<template>
    <div class="notification" :class="{'is-error': $store.state.notification.error, 'is-showed': showed}">
        <button class="notification-close" @click="close">
            <svg-sprite icon="fermer-blanc" width="17" height="17" />
        </button>
        <div class="notification-body">
            {{ $store.state.notification.message }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Notification',
        methods: {
            close() {
                this.$store.commit('notification/close')
            }
        },
        computed: {
            showed() {
                return this.$store.state.notification.showed
            }
        },
        watch: {
            showed() {
                if(this.showed) {
                    this.hideTimeout && clearTimeout(this.hideTimeout)
                    this.hideTimeout = setTimeout(() => {
                        this.close()
                    }, 5000)
                }
            }
        }
    }
</script>
