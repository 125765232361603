<template>
    <div class="cart-item is-total">
        <div class="cart-item-body">
            <div class="cart-item-title">
                {{ $t('cart.totalHt') }}
                <span>({{ $t('cart.noShipping') }})</span>
            </div>
        </div>
        <div class="cart-item-price">
            {{ total | price }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CartTotal',
        props: {
            total: Number
        }
    }
</script>
