<template>
    <div>
        <div class="popup-content">
            <div class="popup-main has-no-padding">
                <div class="form" :class="{'is-loading': loading}">
                    <div class="form-loader">
                        <loader/>
                    </div>
                    <div class="popup-preview">
                        <img :src="this.imgSrc" alt="" v-if="this.imgSrc" ref="img">
                        <button class="popup-preview-arrow is-prev" v-if="currentImgIndex > 0" @click="currentImgIndex--"></button>
                        <button class="popup-preview-arrow is-next" v-if="currentImgIndex < screens.length - 1" @click="currentImgIndex++"></button>
                        <button class="button is-large is-rounded is-hidden-mobile" @click.prevent="dlImage" :class="{
                            'is-success': copied }">
                            {{ copied ? $t('imageCopied') : 'Télécharger l\'image' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import screenshots from "../../../utils/screenshots";

    export default {
        name: 'PopupPreview',
        data: () => ({
            loading: false,
            currentImgIndex: 0,
            screens: [],
            copied: false
        }),
        mounted() {
            this.generateImage()
        },
        computed: {
            imgSrc() {
                return this.screens[this.currentImgIndex] ? this.screens[this.currentImgIndex].data : null
            }
        },
        methods: {
            /**
             * generate image from api
             * @returns {Promise<void>}
             */
            generateImage() {
                this.loading = true
                this.currentImgIndex = 0
                screenshots.generateScreenshots((screens) => {
                    this.loading = false
                    this.screens = screens
                })
            },
            /**
             * copy image to clipboard
             */
            copyImage() {
                this.copyTimeout && clearTimeout(this.copyTimeout)
                var canvas = document.createElement('canvas')
                canvas.width = this.$refs.img.width
                canvas.height = this.$refs.img.height

                var ctx = canvas.getContext('2d')
                ctx.drawImage(this.$refs.img, 0, 0, canvas.width, canvas.height)

                canvas.toBlob((imgBlob) => {
                    try {
                        navigator.clipboard.write([
                            new ClipboardItem({
                                'image/png': imgBlob
                            })
                        ]);
                        this.copied = true
                        this.copyTimeout = setTimeout(() => {
                            this.copied = false
                        }, 3000)
                    } catch (error) {
                        console.error(error);
                    }
                })
            },
            /**
             * download image
             */
            dlImage() {
                if(this.imgSrc) {
                    var link = document.createElement('a')
                    link.download = 'dimo-personnalisation.png'
                    link.href = this.imgSrc
                    link.click()
                }
            },
        },
    }
</script>
