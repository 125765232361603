import Vue from 'vue'
import App from './App.vue'
import store from './store/'
import SvgSprite from './components/ui/SvgSprite'
import Loader from './components/ui/Loader'
import './vue-filters'
import VueI18n from 'vue-i18n'
import langfr from './locales/fr'
import PerfectScrollbar from 'vue2-perfect-scrollbar'

import 'swiper/dist/css/swiper.css'

Vue.use(PerfectScrollbar)

Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'fr',
    messages: {
      fr: langfr
    }
})

Vue.config.productionTip = false
Vue.component('svg-sprite', SvgSprite)
Vue.component('loader', Loader)

new Vue({
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
