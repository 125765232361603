<template>
    <div>
        <div class="popup-header" :class="{'is-success': saved}">
            <div class="popup-title">{{ $t('pao.title') }}</div>
            <div class="popup-subtitle">{{ $t('pao.subtitle') }}</div>
        </div>
        <div class="popup-content">
            <div class="popup-side is-hidden-mobile">
                <div class="popup-side-preview">
                    <img v-if="previewImage" :src="previewImage" alt="">
                    <div v-else class="imgload">
                        <div>
                            <Loader />
                        </div>
                    </div>
                    <h2>
                        {{ $store.state.product.name }}<br>
                        Ref. <b>{{ $store.state.product.refCata }}</b>
                    </h2>
                </div>
            </div>
            <div class="popup-main">
                <div class="slides">
                    <div class="slides-inner" :style="slidesStyle">
                        <form action="" class="form slide" @submit.prevent="saveMessage">
                            <div class="popup-html" style="margin-bottom: 20px;" v-html="$t('pao.messageHelp')"></div>
                            <div class="form-helps is-hidden-mobile">
                                <div class="form-help is-ok">
                                    <div class="form-help-title">{{ $t('pao.canDo') }}</div>
                                    <div class="form-help-content">{{ $t('pao.canDoText') }}</div>
                                </div>
                                <div class="form-help is-nok">
                                    <div class="form-help-title">{{ $t('pao.cantDo') }}</div>
                                    <div class="form-help-content">{{ $t('pao.cantDoText') }}</div>
                                </div>
                            </div>
                            <div class="form-line">
                                <div class="form-control">
                                    <textarea id="paomessage" rows="5" class="form-textarea" :placeholder="$t('pao.messagePlaceholder')" v-model="message"></textarea>
                                </div>
                            </div>
                            <div class="form-submit">
                                <button class="button is-large is-rounded" type="submit">{{ $t('pao.send') }}</button>
                            </div>
                        </form>
                        <div class="form-confirm slide">
                            <div class="form-confirm-message" v-html="$t('pao.ok')"></div>
                            <div class="form-confirm-button">
                                <button class="button is-large is-rounded" @click="close">{{ $t('close') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from '../../ui/Loader'
    import screenshots from "../../../utils/screenshots"

    export default {
        name: 'PopupPao',
        components: {
            Loader
        },
        data: () => ({
            saved: false,
            previewImage: null
        }),
        mounted() {
            this.saved = false
            this.generateImagePreview()
        },
        computed: {
            message: {
                set(value) {
                    this.$store.commit('product/setHelp', value)
                },
                get() {
                    return this.$store.getters['product/help']
                }
            },
            slidesStyle() {
                return {
                    width: '200%',
                    transform: this.saved ? 'translate(-50%, 0)' : 'none'
                }
            }
        },
        methods: {
            saveMessage() {
                this.saved = true
            },
            close() {
                this.$store.commit('popup/close')
            },
            /**
             * generate image from api
             * @returns {Promise<void>}
             */
            generateImagePreview() {
                this.loading = true

                screenshots.generateScreenshots((screens) => {
                    this.loading = false
                    if(screens && screens.length) {
                        this.previewImage = screens[0].data
                    }
                })
            }
        }
    }
</script>
