<template>
    <div class="texts-item">
        <textarea
            class="texts-item-input"
            type="text" placeholder="Votre texte ici"
            v-model="content" ref="contentInput"
            @focus="onInputFocus"
            @change="onInputChange"
            @input="onInputUpdate"
        ></textarea>
        <button class="lightbutton texts-item-button is-red" @click.prevent="remove">
            <svg-sprite icon="fermer-rouge" width="12" height="12" />
            <span>{{ $t('delete') }}</span>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'SidebarTextItem',
        props: {
            text: Object,
            index: String,
        },
        computed: {
            /**
             * text content, v-model of text input
             */
            content: {
                get() {
                    return this.text.content
                },
                set(value) {
                    const lines = value.split('\n')
                    if(lines.length > 4) {
                        value = lines.slice(0, 4).join('\n')
                        this.$refs.contentInput.value = value
                    }
                    this.$store.commit('editor/updateItemProperty', {
                        index: this.index,
                        name: 'content',
                        value: value
                    })
                },
            },
        },
        mounted() {
            // focus on content input if text was just added
            if(this.text.added) {
                this.$refs.contentInput.focus()
                this.onInputUpdate()
                this.$store.commit('editor/updateItemProperty', {
                    index: this.index,
                    name: 'added',
                    value: false
                })
            }
        },
        methods: {
            remove() {
                this.$store.commit('editor/removeItem', this.index)
                this.$store.dispatch('history/snapshot')
            },
            /**
             * input focused, select text in editor
             */
            onInputFocus() {
                this.$store.commit('editor/selectItem', this.index)
            },
            /**
             * input change, take history snapshot
             */
            onInputChange() {
                this.$store.dispatch('history/snapshot')
            },
            /**
             * input change, resize
             */
            onInputUpdate() {
                this.$refs.contentInput.style.height = "1px";
                this.$refs.contentInput.style.height = (1 + this.$refs.contentInput.scrollHeight)+"px";
            }
        }
    }
</script>
