<template>
    <div class="header">
        <div class="header-body box">
            <div class="header-title">{{ $store.state.product.refCata }}</div>
            <div class="header-subtitle">{{ $store.state.product.name }}</div>
        </div>
        <button class="header-back" @click="onClickBack">
            <span>
                Retour<br>
                au produit
            </span>
            <span class="header-back-close"></span>
        </button>
        <button class="header-back is-mobile" @click="onClickClose">
            <span>
                Fermer
            </span>
            <span class="header-back-close"></span>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'Header',
        methods: {
            onClickBack() {
                window.parent.postMessage({
                    type: 'configuratorClose'
                }, '*')
            },
            onClickClose() {
                this.$store.commit('toggleSidebar')
            }
        }
    }
</script>
