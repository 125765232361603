<template>
    <transition name="options-slide">
        <div class="options" v-if="image">
            <div class="options-group">
                <button class="toolsbutton" @click.prevent="edit">
                    <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="editer" />
                    <span class="toolsbutton-text">{{ $t('edit') }}</span>
                </button>
                <button class="toolsbutton" @click.prevent="crop">
                    <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="recadrer" />
                    <span class="toolsbutton-text">{{ $t('crop') }}</span>
                </button>
<!--                <button class="toolsbutton" @click.prevent="deleteImage">-->
<!--                    <svg-sprite classname="toolsbutton-icon" width="40" height="40" icon="supprimer" />-->
<!--                    <span class="toolsbutton-text">{{ $t('delete') }}</span>-->
<!--                </button>-->
            </div>
        </div>
    </transition>
</template>

<script>
    import {mapGetters} from 'vuex'
    import Constants from '../../config/Constants'
    import _ from 'lodash'

    export default {
        name: 'ViewerImageOptions',
        components: {},
        computed: {
            ...mapGetters({
                selectedMarquage: 'marquage/selected'
            }),
            image() {
                return this.$store.getters['editor/selectedItem']({
                    marquage: this.selectedMarquage,
                    type: Constants.EDITOR.TYPE_IMAGE
                })
            }
        },
        methods: {
            /**
             * duplicate current image in editor
             */
            duplicate() {
                this.$store.commit('editor/addItem', {
                    marquage: this.selectedMarquage,
                    type: Constants.EDITOR.TYPE_IMAGE,
                    defaultValues: {
                        imageObj: _.cloneDeep(this.image.item.imageObj),
                        originalObj: {...this.image.item.originalObj},
                        options: {...this.image.item.options},
                        cropOptions: this.image.item.cropOptions ? {...this.image.item.cropOptions} : null
                    }
                })
            },
            edit() {
                this.$store.commit('popup/open', {
                    slug: 'PopupImageEdit',
                    params: {
                        image: this.image.item,
                        edit: true,
                        index: this.image.index
                    }
                })
            },
            crop() {
                this.$store.commit('popup/open', {
                    slug: 'PopupImageCrop',
                    params: {
                        image: this.image.item,
                        index: this.image.index
                    }
                })
            },
            deleteImage() {
                this.$store.commit('editor/removeItem', this.image.index)
                this.$store.dispatch('history/snapshot')
            }
        }
    }
</script>
