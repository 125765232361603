<template>
    <transition name="popup">
        <div class="popup" v-show="active" @mousedown.self="closePopup" :class="popupClasses">
            <div class="popup-body">
                <div class="popup-close" @click="closePopup">
                    <svg-sprite icon="fermer-noir" width="17" height="17" />
                </div>
                <component :is="slug" ref="popupComponent"></component>
            </div>
        </div>
    </transition>
</template>

<script>
    import { mapState } from 'vuex'
    import PopupPao from './screens/PopupPao'
    import PopupImageCrop from './screens/PopupImageCrop'
    import PopupImageEdit from './screens/PopupImageEdit'
    import PopupLibrary from './screens/PopupLibrary'
    import PopupTechniques from './screens/PopupTechniques'
    import PopupShare from './screens/PopupShare'
    import PopupPreview from './screens/PopupPreview'
    import PopupMotifs from './screens/PopupMotifs'
    import PopupLightinfo from './screens/PopupLightinfo'
    import PopupGrilleTarifs from './screens/PopupGrilleTarifs'

    export default {
        name: 'Popup',
        components: {PopupImageCrop, PopupImageEdit, PopupPao, PopupLibrary, PopupTechniques, PopupShare,
            PopupPreview, PopupMotifs, PopupLightinfo, PopupGrilleTarifs},
        computed: {
            ...mapState({
                active: state => state.popup.active,
                slug: state => state.popup.slug,
                params: state => state.popup.params
            }),
            popupClasses() {
                const tinyPopups = ['PopupPreview', 'PopupLightinfo', 'PopupGrilleTarifs']
                return {
                    'is-tiny': tinyPopups.includes(this.slug)
                }
            }
        },
        methods: {
            closePopup() {
                this.$store.commit('popup/close')
            }
        }
    }
</script>
