<script>
import TextCanvas from "../../utils/TextCanvas";

export default {
    name: "ScreenshotItemText",
    props: {
        text: Object,
        variant: Object
    },
    computed: {
        styles() {
            const wPercent = this.text.width / this.variant.width
            const hPercent = this.text.height / this.variant.height
            const leftPercent = (this.text.x / this.variant.width) - (wPercent / 2)
            const topPercent = (this.text.y / this.variant.height) - (hPercent / 2)
            return {
                top: (topPercent * 100) + '%',
                left: (leftPercent * 100) + '%',
                width: (wPercent * 100) + '%',
                height: (hPercent  * 100) + '%',
                transform: 'rotate('+this.text.rotation+'deg)'
            }
        }
    },
    watch: {
        // item content or font family changed, update element width fo fit the item
        'text.content'() {
            this.renderText()
        },
        'text.fontFamily'() {
            this.renderText()
        },
        'text.bold'() {
            this.renderText()
        },
        'text.italic'() {
            this.renderText()
        },
        'text.color'() {
            this.renderText(false)
        },
        'text.lineHeight'() {
            this.renderText()
        },
        'text.align'() {
            this.renderText()
        },
        'text.width'() {
            this.renderText()
        },
        laserColor() {
            this.renderText(false)
        }
    },
    mounted() {
        this.textCanvasContext = this.$refs.textCanvas.getContext('2d')
        this.renderText()
    },
    methods: {
        /**
         * content has changed, render canvas text
         */
        renderText() {
            const color = this.variant.laserColor ? this.variant.laserColor : this.text.color
            const measures = TextCanvas.draw(this.$refs.textCanvas, this.textCanvasContext, this.text.content, this.text.fontSize, this.text.fontFamily, this.text.align, this.text.lineHeight, color, this.text.bold, this.text.italic)
        }
    }
}
</script>

<template>
    <div class="screenshot-item is-text" v-if="!text.deleted" :style="styles">
        <canvas ref="textCanvas"></canvas>
    </div>
</template>
