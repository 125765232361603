import Vue from 'vue'

const state = {
    histories: {}
}

const getters = {
}

const actions = {
    /**
     * create a snapshot for history, for all marquages
     * @param commit
     * @param rootGetters
     */
    snapshotAll({commit, rootState, rootGetters}) {
        const marquages = rootState.marquage.items
        for(const marquage of Object.values(marquages)) {
            commit('addVersion', {
                marquage: marquage.id,
                version: {
                    editor: rootGetters['editor/historyVersion'](marquage)
                }
            })
        }
    },
    /**
     * create a snapshot for history, for selected marquage
     * @param commit
     * @param rootGetters
     */
    snapshot({commit, rootGetters}) {
        const marquage = rootGetters['marquage/selected']
        if(marquage) {
            commit('addVersion', {
                marquage,
                version: {
                    editor: rootGetters['editor/historyVersion'](marquage)
                }
            })
        }
    },
    /**
     * go back in history for selected marquage
     * @param state
     * @param commit
     * @param rootGetters
     * @param direction
     */
    navigate({state, commit, rootGetters}, direction) {
        const marquage = rootGetters['marquage/selected']
        if(marquage && state.histories[marquage]) {
            const history = state.histories[marquage]
            const newVersion = history.currentVersion + direction
            if(newVersion >= 0 && newVersion < history.versions.length) {
                commit('editor/setHistoryVersion', {
                    version: history.versions[newVersion].editor,
                    marquage: marquage
                }, {root: true})
                commit('setCurrentVersion', {
                    marquage,
                    newVersion
                })
            }
        }
    }
}

const mutations = {
    /**
     * add version to a marquage history
     * @param state
     * @param marquage
     * @param version
     */
    addVersion(state, {marquage, version}) {
        if(!state.histories[marquage]) {
            Vue.set(state.histories, marquage, {
                versions: [],
                currentVersion: 0
            })
        }
        const marquageHistory = state.histories[marquage]
        // if we are not in last version of history, remove all versions after current index
        if(marquageHistory.currentVersion < marquageHistory.versions.length - 1) {
            marquageHistory.versions.splice(marquageHistory.currentVersion + 1, marquageHistory.versions.length - (marquageHistory.currentVersion + 1))
        }

        // add version to array and update current version index
        marquageHistory.versions.push(version)
        marquageHistory.currentVersion = marquageHistory.versions.length - 1
    },
    /**
     * navigate on marquage history, update current version index
     * @param state
     * @param marquage
     * @param newVersion
     */
    setCurrentVersion(state, {marquage, newVersion}) {
        if(state.histories[marquage]) {
            state.histories[marquage].currentVersion = newVersion
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}