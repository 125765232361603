
const state = {
    active: false,
    slug: '',
    params: {}
}

const getters = {

}

const actions = {
}

const mutations = {
    open(state, {slug, params = {}}) {
        state.active = true
        state.slug = slug
        state.params = params
    },
    close(state) {
        state.active = false
        state.slug = ''
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
