<template>
    <div class="colorpicker-results" v-if="results.length">
        <div
            class="colorpicker-result"
            v-for="(color, index) in results"
            :key="color.hex"
            :class="{ 'is-active': index === selectedIndex }"
            @click="$emit('selected', color)"
        >
            <ColorPickerItem :color="color.hex" />
            <div class="colorpicker-result-text">{{ color.pantone }}</div>
        </div>
    </div>
</template>

<script>
    import pantones from '../../config/pantones'
    import ColorPickerItem from './ColorPickerItem'

    export default {
        name: 'ColorPickerResults',
        components: {ColorPickerItem},
        props: {
            search: String
        },
        data: () => ({
            selectedIndex: -1
        }),
        mounted() {
            document.addEventListener('keydown', this.onKeyup)
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.onKeyup)
        },
        computed: {
            results() {
                let results = []
                if(this.search.length > 2) {
                    results = pantones.filter(item => item.pantone.startsWith(this.search))
                }
                return results
            }
        },
        watch: {
            results() {
                this.selectedIndex = -1
            }
        },
        methods: {
            /**
             * navigate trough results with up / down arrow, and select with enter
             * @param event
             */
            onKeyup(event) {
                if(!this.results) return

                if(event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13) {
                    event.preventDefault()
                }
                switch(event.keyCode) {
                    // UP
                    case 38:
                        this.selectedIndex -= 1
                        if(this.selectedIndex < 0) this.selectedIndex = this.results.length - 1
                        break
                    // DOWN
                    case 40:
                        this.selectedIndex += 1
                        if(this.selectedIndex >= this.results.length) this.selectedIndex = 0
                        break
                    // ENTER
                    case 13:
                        if(this.selectedIndex !== -1 && this.results[this.selectedIndex]) {
                            this.$emit('selected', this.results[this.selectedIndex])
                        } else if(this.results.length === 1) {
                            // only 1 result, select it
                            this.$emit('selected', this.results[0])
                        }
                        break
                }
            }
        }
    }
</script>
