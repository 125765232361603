
export default {
    computed: {
        technique() {
            return this.$store.getters['marquage/currentTechnique'](this.selectedMarquage)
        },
        /**
         * if forced color, disable color change
         * @returns {boolean}
         */
        disableColorEdition() {
            return !!this.technique.laserColor
        }
    }
}
