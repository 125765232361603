<template>
    <transition name="fade">
        <div class="viewer-product-image" v-if="showed">
            <img :src="image.url.replace('web-tab', 'localhost:8000')" alt="">
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'ViewerProductImage',
        props: {
            image: Object,
            showed: Boolean
        }
    }
</script>
