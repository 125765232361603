
export default {
    EDITOR: {
        TYPE_TEXT: 'text',
        TYPE_IMAGE: 'image',
        TYPE_CLIPART: 'clipart'
    },
    MAX_TEXTS: 8,
    FONTS: [
        {
            label: 'Arial',
            family: 'Arial'
        },
        {
            label: 'Calibri',
            family: 'Calibri'
        },
        {
            label: 'Comic sans',
            family: 'Comic sans MS'
        },
        {
            label: 'Courier',
            family: 'Courier'
        },
        {
            label: 'Georgia',
            family: 'Georgia'
        },
        {
            label: 'Helvetica',
            family: 'Helvetica'
        },
        {
            label: 'Impact',
            family: 'Impact'
        },
        {
            label: 'Lucida',
            family: 'Lucida Sans'
        },
        {
            label: 'Myriad',
            family: 'Myriad'
        },
        {
            label: 'Segoe',
            family: 'Segoe UI'
        },
        {
            label: 'Tahoma',
            family: 'Tahoma'
        },
        {
            label: 'Times',
            family: 'Times New Roman'
        },
        {
            label: 'Trebuchet',
            family: 'Trebuchet MS'
        },
        {
            label: 'Verdana',
            family: 'Verdana'
        }
    ],
    DEFAULT_COLOR: '#000'
}
