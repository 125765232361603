<template>
    <div class="sidebar pricebar" :class="{'is-active': $store.state.sidebarActive}">
        <div class="sidebar-body">
            <div class="pricebar-top">
                <Header></Header>
            </div>
            <div class="pricebar-grille box">
                <GrilleTarif />
            </div>
            <div class="pricebar-body box">
                <Cart />
            </div>
        </div>
    </div>
</template>

<script>
import Cart from '../cart/Cart'
import Header from "../ui/Header.vue";
import GrilleTarif from "./GrilleTarif.vue";

export default {
    name: 'Pricebar',
    components: {GrilleTarif, Header, Cart}
}
</script>
