<template>
    <div class="options-input is-select">
        <select :value="value" @input="onInput">
            <option v-for="family in families" :value="family.family" :key="family.family">{{ family.label }}</option>
        </select>
    </div>
</template>

<script>
    import Constants from '../../config/Constants'

    export default {
        name: 'FontFamilyPicker',
        props: {
            value: String
        },
        data: () => ({
            families: Constants.FONTS
        }),
        methods: {
            onInput(e) {
                this.$emit('input', e.target.value)
            }
        }
    }
</script>
