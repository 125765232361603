<template>
    <div>
        <div class="popup-header">
            <div class="popup-title">{{ $t('library.title') }}</div>
            <div class="popup-subtitle">{{ $t('library.subtitle') }}</div>
        </div>
        <div class="popup-content">
            <div class="popup-side">
                <PopupMenu :links="menuLinks" v-model="currentMenu" />
            </div>
            <div class="popup-main">
                <component :is="currentMenu"></component>
            </div>
        </div>
    </div>
</template>

<script>
    import ScreenUpload from './library/ScreenUpload'
    import ScreenMyLogos from './library/ScreenMyLogos'
    import ScreenMyIcons from './library/ScreenMyIcons'
    import PopupMenu from '../PopupMenu'

    export default {
        name: 'PopupPao',
        components: {PopupMenu, ScreenUpload, ScreenMyIcons, ScreenMyLogos},
        data: () => ({
            currentMenu: null
        }),
        mounted() {
            this.currentMenu = 'ScreenUpload'
        },
        computed: {
            menuLinks() {
                return {
                    ScreenUpload: 'library.menu.add',
                    // ScreenMyLogos: 'library.menu.saved'
                }
            }
        },
        methods: {
        }
    }
</script>
