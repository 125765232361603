
const state = {
    id: '',
    idProduct: '',
    ref: '',
    refCata: '',
    name: '',
    description: '',
    comment: '',
    help: '',
    qtyMin: 0,
    qtyStep: 0,
    qtyMix: false,
    light: false
}

const getters = {
    help: state => {
        return state.help
    },
    light: state => {
        return state.light
    },
    idProduct: state => {
        return state.idProduct
    },
    qtyMin: state => {
        return state.qtyMin
    },
    qtyStep: state => {
        return state.qtyStep
    },
    qtyMix: state => {
        return state.qtyMix
    }
}

const actions = {
}

const mutations = {
    /**
     * init with api infos
     * @param state
     * @param api
     */
    init(state, api) {
        state.id = api.id
        state.idProduct = api.idProduct
        state.ref = api.ref
        state.refCata = api.refCata
        state.name = api.name
        state.comment = api.comment
        state.description = api.description
        state.help = api.help
        state.light = api.light
        state.qtyMin = api.qtyMin
        state.qtyMix = api.qtyMix
        state.qtyStep = api.qtyStep
    },
    setHelp(state, help) {
        state.help = help
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
