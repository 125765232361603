<template>
    <div class="mediagrid-item" @click.prevent="$emit('toggle')" :class="itemClass">
        <img :src="imgUrl" alt="" @load="$emit('imgLoad')">
    </div>
</template>

<script>
    export default {
        name: 'MediaGridItem',
        props: {
            item: Object,
            selected: Boolean
        },
        computed: {
            itemClass() {
                return {
                    'is-selected': this.selected
                }
            },
            imgUrl() {
                return this.item.path
            }
        }
    }
</script>
