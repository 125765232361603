<script>
import logo from "../ui/Logo.vue";

export default {
    name: "SidebarQuantityLight",
    computed: {
        currentQuantity() {
            const selectedOption = this.$store.getters['variants/selectedOption']()
            return selectedOption && selectedOption.config.quantity !== 0 ? selectedOption.config.quantity : ''
        }
    },
    methods: {
        setQuantity(event) {
            let qty = 0
            if (event.target.value.length > 0) {
                qty = event.target.value
            }
            const selectedOption = this.$store.getters['variants/selectedOption']()
            if(selectedOption) {
                let subvariantId = null
                if(selectedOption.hasSubVariants && selectedOption.subVariants.length) {
                    subvariantId = selectedOption.subVariants[0].id
                }

                this.$store.commit('variants/setOptionQuantity', {
                    variantId: null,
                    optionId: selectedOption.id,
                    subVariantId: subvariantId,
                    quantity: qty
                })
            }
        }
    }
}
</script>

<template>
    <div class="sidebar-section">
        <div class="sidebar-section-header inner box has-large-padding">
            <div class="sidebar-quantitylight">
                <div class="sidebar-title">Quantité</div>
                <input type="number" class="variantqty-input" min="0"
                       :value="currentQuantity"
                       @input="setQuantity">
            </div>
        </div>
    </div>
</template>