<template>
    <transition name="options-slide">
        <div class="options" v-if="text">
            <div class="options-icon">
                <svg-sprite icon="text" width="18" height="18" />
            </div>

            <div class="options-group">
                <FontFamilyPicker v-model="fontFamily"/>
            </div>

            <div class="options-group">
                <button
                    class="options-toggle"
                    :class="{ 'is-active': text.item.italic }"
                    @click.prevent="toggle('italic')"
                >
                    <svg-sprite icon="italic" width="18" height="18" />
                </button>
                <button
                    class="options-toggle"
                    :class="{ 'is-active': text.item.bold }"
                    @click.prevent="toggle('bold')"
                >
                    <svg-sprite icon="bold" width="18" height="18" />
                </button>
                <div class="options-radio">
                    <button
                        class="options-radio-item"
                        :class="{ 'is-active': text.item.lineHeight === 1 }"
                        @click.prevent="setLineHeight(1)"
                    >
                        <span class="icontext is-small"><span></span><span></span><span></span></span>
                    </button>
                    <button
                        class="options-radio-item"
                        :class="{ 'is-active': text.item.lineHeight === 1.3 }"
                        @click.prevent="setLineHeight(1.3)"
                    >
                        <span class="icontext"><span></span><span></span><span></span></span>
                    </button>
                    <button
                        class="options-radio-item"
                        :class="{ 'is-active': text.item.lineHeight === 1.6 }"
                        @click.prevent="setLineHeight(1.6)"
                    >
                        <span class="icontext is-large"><span></span><span></span><span></span></span>
                    </button>
                </div>
                <div class="options-radio">
                    <button
                        class="options-radio-item"
                        :class="{ 'is-active': text.item.align === 'left' }"
                        @click.prevent="setAlign('left')"
                    >
                        <span class="icontext is-align is-left"><span></span><span></span><span></span></span>
                    </button>
                    <button
                        class="options-radio-item"
                        :class="{ 'is-active': text.item.align === 'center' }"
                        @click.prevent="setAlign('center')"
                    >
                        <span class="icontext is-align is-center"><span></span><span></span><span></span></span>
                    </button>
                    <button
                        class="options-radio-item"
                        :class="{ 'is-active': text.item.align === 'right' }"
                        @click.prevent="setAlign('right')"
                    >
                        <span class="icontext is-align is-right"><span></span><span></span><span></span></span>
                    </button>
                </div>
                <!--<button
                    class="options-toggle"
                    :class="{ 'is-active': text.item.underline }"
                    @click.prevent="toggle('underline')"
                >
                    <svg-sprite icon="underline" width="18" height="18" />
                </button>-->

                <!--
                <div class="options-group-spacer"></div>
                <button
                    class="options-toggle"
                    :class="{ 'is-active': text.item.align === 'left' }"
                    @click.prevent="setAlign('left')"
                >L</button>
                <button
                    class="options-toggle"
                    :class="{ 'is-active': text.item.align === 'center' }"
                    @click.prevent="setAlign('center')"
                >C</button>
                <button
                    class="options-toggle"
                    :class="{ 'is-active': text.item.align === 'justify' }"
                    @click.prevent="setAlign('justify')"
                >J</button>
                <button
                    class="options-toggle"
                    :class="{ 'is-active': text.item.align === 'right' }"
                    @click.prevent="setAlign('right')"
                >R</button>
                -->
            </div>

            <div class="options-group">
                <ColorPicker v-model="color" :disabled="disableColorEdition" :colors-preset="colorsPreset" />
            </div>
        </div>
    </transition>
</template>

<script>
    import {mapGetters} from 'vuex'
    import Constants from '../../config/Constants'
    import FontFamilyPicker from '../picker/FontFamilyPicker'
    import ColorPicker from '../picker/ColorPicker'
    import ViewerItemOptions from '../../mixins/ViewerItemOptions'

    export default {
        name: 'ViewerTextOptions',
        components: {ColorPicker, FontFamilyPicker},
        mixins: [ViewerItemOptions],
        computed: {
            ...mapGetters({
                selectedMarquage: 'marquage/selected'
            }),
            text() {
                return this.$store.getters['editor/selectedItem']({
                    marquage: this.selectedMarquage,
                    type: Constants.EDITOR.TYPE_TEXT
                })
            },
            fontFamily: {
                get() {
                    return this.text.item.fontFamily
                },
                set(value) {
                    this.$store.commit('editor/updateItemProperty', {
                        index: this.text.index,
                        name: 'fontFamily',
                        value: value
                    })
                    this.$store.dispatch('history/snapshot')
                }
            },
            color: {
                get() {
                    return this.technique.laserColor ? this.technique.laserColor : this.text.item.color
                },
                set(value) {
                    this.$store.dispatch('editor/updateItemColor', {
                        index: this.text.index,
                        color: value
                    })
                    this.$store.dispatch('history/snapshot')
                }
            },
            /**
             * if we used all colors available on technique, limit colors to already used ones
             */
            colorsPreset() {
                let preset = false
                const colors = this.$store.getters['editor/itemsColors'](this.selectedMarquage)

                if(this.technique.nbColorIncluded > 1 && colors.length >= this.technique.nbColorIncluded) {
                    preset = colors
                }

                return preset
            }
        },
        methods: {
            toggle(property) {
                this.$store.commit('editor/updateItemProperty', {
                    index: this.text.index,
                    name: property,
                    value: !this.text.item[property]
                })
                this.$store.dispatch('history/snapshot')
            },
            setAlign(value) {
                this.$store.commit('editor/updateItemProperty', {
                    index: this.text.index,
                    name: 'align',
                    value: value
                })
            },
            setLineHeight(value) {
                this.$store.commit('editor/updateItemProperty', {
                    index: this.text.index,
                    name: 'lineHeight',
                    value: value
                })
            }
        }
    }
</script>
