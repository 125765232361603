<template>
    <div>
        <p>Logos</p>
    </div>
</template>

<script>
    export default {
        name: 'ScreenMyLogos',
    }
</script>

