<template>
    <div>
        <div class="popup-header">
            <div class="popup-title">{{ $t('techniques.title') }}</div>
            <div class="popup-subtitle">{{ $t('techniques.subtitle') }}</div>
        </div>
        <div class="popup-content">
            <div class="popup-side">
                <PopupMenu :links="menuLinks" v-model="currentMenu" :translate="false" />
            </div>
            <div class="popup-main form" :class="{'is-loading': loading}">
                <div class="form-loader">
                    <loader />
                </div>
                <div class="popup-edito">
                    <div class="popup-edito-inner">
                        <perfect-scrollbar ref="scroll">
                            <div v-if="currentTechnique" class="cms">
                                <h1>{{ currentTechnique.label }}</h1>
                                <div v-html="currentTechnique.description"></div>
                                <div class="image">
                                    <img v-if="currentTechnique.techImage" :src="currentTechnique.techImage" alt="">
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>
                </div>
                <div class="form-confirm-button">
                    <button class="button is-large is-rounded" @click.prevent="close">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PopupMenu from '../PopupMenu'
    import axios from 'axios'
    import config from '../../../../config.json'

    export default {
        name: 'PopupTechniques',
        components: {PopupMenu},
        data: () => ({
            loading: false,
            currentMenu: null
        }),
        watch: {
            currentMenu() {
                this.$nextTick(() => {
                    this.$refs.scroll.update()
                })
            }
        },
        mounted() {
            this.currentMenu = this.menuLinks ? Object.keys(this.menuLinks)[0] : ''
        },
        computed: {
            techniques() {
                return this.$store.getters['marquage/techniquesList']
            },
            menuLinks() {
                const techniques = this.$store.getters['marquage/techniquesList']
                const links = {}
                for(const tech of techniques) {
                    links[tech.label] = tech.label
                }
                return links
            },
            currentTechnique() {
                return this.techniques.find(item => item.label === this.currentMenu)
            }
        },
        methods: {
            close() {
                this.$store.commit('popup/close')
            }
        }
    }
</script>
