export default [
    {
        "pantone":"100-c",
        "hex":"#f6eb61"
    },
    {
        "pantone":"101-c",
        "hex":"#f7ea48"
    },
    {
        "pantone":"102-c",
        "hex":"#fce300"
    },
    {
        "pantone":"103-c",
        "hex":"#c5a900"
    },
    {
        "pantone":"104-c",
        "hex":"#af9800"
    },
    {
        "pantone":"105-c",
        "hex":"#897a27"
    },
    {
        "pantone":"7401-c",
        "hex":"#f5e1a4"
    },
    {
        "pantone":"7402-c",
        "hex":"#ecd898"
    },
    {
        "pantone":"7403-c",
        "hex":"#eed484"
    },
    {
        "pantone":"7404-c",
        "hex":"#f4da40"
    },
    {
        "pantone":"7405-c",
        "hex":"#f2cd00"
    },
    {
        "pantone":"7406-c",
        "hex":"#f1c400"
    },
    {
        "pantone":"7407-c",
        "hex":"#cba052"
    },
    {
        "pantone":"106-c",
        "hex":"#f9e547"
    },
    {
        "pantone":"107-c",
        "hex":"#fbe122"
    },
    {
        "pantone":"108-c",
        "hex":"#fedb00"
    },
    {
        "pantone":"109-c",
        "hex":"#ffd100"
    },
    {
        "pantone":"110-c",
        "hex":"#daaa00"
    },
    {
        "pantone":"111-c",
        "hex":"#aa8a00"
    },
    {
        "pantone":"112-c",
        "hex":"#9c8412"
    },
    {
        "pantone":"113-c",
        "hex":"#fae053"
    },
    {
        "pantone":"114-c",
        "hex":"#fbdd40"
    },
    {
        "pantone":"115-c",
        "hex":"#fdda24"
    },
    {
        "pantone":"116-c",
        "hex":"#ffcd00"
    },
    {
        "pantone":"117-c",
        "hex":"#c99700"
    },
    {
        "pantone":"118-c",
        "hex":"#ac8400"
    },
    {
        "pantone":"119-c",
        "hex":"#897322"
    },
    {
        "pantone":"127-c",
        "hex":"#f3dd6d"
    },
    {
        "pantone":"128-c",
        "hex":"#f3d54e"
    },
    {
        "pantone":"129-c",
        "hex":"#f3d03e"
    },
    {
        "pantone":"130-c",
        "hex":"#f2a900"
    },
    {
        "pantone":"131-c",
        "hex":"#cc8a00"
    },
    {
        "pantone":"132-c",
        "hex":"#a07400"
    },
    {
        "pantone":"133-c",
        "hex":"#6c571b"
    },
    {
        "pantone":"1205-c",
        "hex":"#f8e08e"
    },
    {
        "pantone":"1215-c",
        "hex":"#fbd872"
    },
    {
        "pantone":"1225-c",
        "hex":"#ffc845"
    },
    {
        "pantone":"1235-c",
        "hex":"#ffb81c"
    },
    {
        "pantone":"1245-c",
        "hex":"#c69214"
    },
    {
        "pantone":"1255-c",
        "hex":"#ad841f"
    },
    {
        "pantone":"1265-c",
        "hex":"#886b25"
    },
    {
        "pantone":"120-c",
        "hex":"#fbdb65"
    },
    {
        "pantone":"121-c",
        "hex":"#fdd757"
    },
    {
        "pantone":"122-c",
        "hex":"#fed141"
    },
    {
        "pantone":"123-c",
        "hex":"#ffc72c"
    },
    {
        "pantone":"124-c",
        "hex":"#eaaa00"
    },
    {
        "pantone":"125-c",
        "hex":"#b58500"
    },
    {
        "pantone":"126-c",
        "hex":"#9a7611"
    },
    {
        "pantone":"7548-c",
        "hex":"#ffc600"
    },
    {
        "pantone":"7549-c",
        "hex":"#ffb500"
    },
    {
        "pantone":"7550-c",
        "hex":"#d19000"
    },
    {
        "pantone":"7551-c",
        "hex":"#b47e00"
    },
    {
        "pantone":"7552-c",
        "hex":"#73531d"
    },
    {
        "pantone":"7553-c",
        "hex":"#5a4522"
    },
    {
        "pantone":"7554-c",
        "hex":"#4b3d2a"
    },
    {
        "pantone":"7555-c",
        "hex":"#d29f13"
    },
    {
        "pantone":"7556-c",
        "hex":"#b78b20"
    },
    {
        "pantone":"7557-c",
        "hex":"#9f7d23"
    },
    {
        "pantone":"7558-c",
        "hex":"#967126"
    },
    {
        "pantone":"7559-c",
        "hex":"#8f6a2a"
    },
    {
        "pantone":"7560-c",
        "hex":"#7d622e"
    },
    {
        "pantone":"7561-c",
        "hex":"#6c5d34"
    },
    {
        "pantone":"134-c",
        "hex":"#fdd26e"
    },
    {
        "pantone":"135-c",
        "hex":"#ffc658"
    },
    {
        "pantone":"136-c",
        "hex":"#ffbf3f"
    },
    {
        "pantone":"137-c",
        "hex":"#ffa300"
    },
    {
        "pantone":"138-c",
        "hex":"#de7c00"
    },
    {
        "pantone":"139-c",
        "hex":"#af6d04"
    },
    {
        "pantone":"140-c",
        "hex":"#74531c"
    },
    {
        "pantone":"1345-c",
        "hex":"#fdd086"
    },
    {
        "pantone":"1355-c",
        "hex":"#ffc56e"
    },
    {
        "pantone":"1365-c",
        "hex":"#ffb549"
    },
    {
        "pantone":"1375-c",
        "hex":"#ff9e1b"
    },
    {
        "pantone":"1385-c",
        "hex":"#d57800"
    },
    {
        "pantone":"1395-c",
        "hex":"#996017"
    },
    {
        "pantone":"1405-c",
        "hex":"#6e4c1e"
    },
    {
        "pantone":"141-c",
        "hex":"#f2c75c"
    },
    {
        "pantone":"142-c",
        "hex":"#f1be48"
    },
    {
        "pantone":"143-c",
        "hex":"#f1b434"
    },
    {
        "pantone":"144-c",
        "hex":"#ed8b00"
    },
    {
        "pantone":"145-c",
        "hex":"#cf7f00"
    },
    {
        "pantone":"146-c",
        "hex":"#a76d11"
    },
    {
        "pantone":"147-c",
        "hex":"#715c2a"
    },
    {
        "pantone":"7408-c",
        "hex":"#f6be00"
    },
    {
        "pantone":"7409-c",
        "hex":"#f0b323"
    },
    {
        "pantone":"7410-c",
        "hex":"#fead77"
    },
    {
        "pantone":"7411-c",
        "hex":"#e6a65d"
    },
    {
        "pantone":"7412-c",
        "hex":"#d38235"
    },
    {
        "pantone":"7413-c",
        "hex":"#dc8633"
    },
    {
        "pantone":"7414-c",
        "hex":"#c16c18"
    },
    {
        "pantone":"7562-c",
        "hex":"#bd9b60"
    },
    {
        "pantone":"7563-c",
        "hex":"#d69a2d"
    },
    {
        "pantone":"7564-c",
        "hex":"#db8a06"
    },
    {
        "pantone":"7565-c",
        "hex":"#cd7925"
    },
    {
        "pantone":"7566-c",
        "hex":"#ad6433"
    },
    {
        "pantone":"7567-c",
        "hex":"#89532f"
    },
    {
        "pantone":"7568-c",
        "hex":"#775135"
    },
    {
        "pantone":"7569-c",
        "hex":"#d78825"
    },
    {
        "pantone":"7570-c",
        "hex":"#d3832b"
    },
    {
        "pantone":"7571-c",
        "hex":"#c67d30"
    },
    {
        "pantone":"7572-c",
        "hex":"#b67233"
    },
    {
        "pantone":"7573-c",
        "hex":"#a7662b"
    },
    {
        "pantone":"7574-c",
        "hex":"#9e6a38"
    },
    {
        "pantone":"7575-c",
        "hex":"#835d32"
    },
    {
        "pantone":"712-c",
        "hex":"#fcc89b"
    },
    {
        "pantone":"713-c",
        "hex":"#fdbe87"
    },
    {
        "pantone":"714-c",
        "hex":"#fdaa63"
    },
    {
        "pantone":"715-c",
        "hex":"#f68d2e"
    },
    {
        "pantone":"716-c",
        "hex":"#ea7600"
    },
    {
        "pantone":"717-c",
        "hex":"#d45d00"
    },
    {
        "pantone":"718-c",
        "hex":"#be4d00"
    },
    {
        "pantone":"148-c",
        "hex":"#fecb8b"
    },
    {
        "pantone":"149-c",
        "hex":"#ffc27b"
    },
    {
        "pantone":"150-c",
        "hex":"#ffb25b"
    },
    {
        "pantone":"151-c",
        "hex":"#ff8200"
    },
    {
        "pantone":"152-c",
        "hex":"#e57200"
    },
    {
        "pantone":"153-c",
        "hex":"#be6a14"
    },
    {
        "pantone":"154-c",
        "hex":"#9b5a1a"
    },
    {
        "pantone":"155-c",
        "hex":"#efd19f"
    },
    {
        "pantone":"156-c",
        "hex":"#efbe7d"
    },
    {
        "pantone":"157-c",
        "hex":"#eca154"
    },
    {
        "pantone":"158-c",
        "hex":"#e87722"
    },
    {
        "pantone":"159-c",
        "hex":"#cb6015"
    },
    {
        "pantone":"160-c",
        "hex":"#a1561c"
    },
    {
        "pantone":"161-c",
        "hex":"#603d20"
    },
    {
        "pantone":"1485-c",
        "hex":"#ffae62"
    },
    {
        "pantone":"1495-c",
        "hex":"#ff8f1c"
    },
    {
        "pantone":"1505-c",
        "hex":"#ff6900"
    },
    {
        "pantone":"1525-c",
        "hex":"#b94700"
    },
    {
        "pantone":"1535-c",
        "hex":"#94450b"
    },
    {
        "pantone":"1545-c",
        "hex":"#653819"
    },
    {
        "pantone":"1555-c",
        "hex":"#ffb990"
    },
    {
        "pantone":"1565-c",
        "hex":"#ffa06a"
    },
    {
        "pantone":"1575-c",
        "hex":"#ff7f32"
    },
    {
        "pantone":"1585-c",
        "hex":"#ff6a13"
    },
    {
        "pantone":"1595-c",
        "hex":"#d86018"
    },
    {
        "pantone":"1605-c",
        "hex":"#a65523"
    },
    {
        "pantone":"1615-c",
        "hex":"#8b4720"
    },
    {
        "pantone":"162-c",
        "hex":"#ffbe9f"
    },
    {
        "pantone":"163-c",
        "hex":"#ff9d6e"
    },
    {
        "pantone":"164-c",
        "hex":"#ff7f41"
    },
    {
        "pantone":"165-c",
        "hex":"#ff671f"
    },
    {
        "pantone":"166-c",
        "hex":"#e35205"
    },
    {
        "pantone":"167-c",
        "hex":"#be531c"
    },
    {
        "pantone":"168-c",
        "hex":"#73381d"
    },
    {
        "pantone":"7576-c",
        "hex":"#db864e"
    },
    {
        "pantone":"7577-c",
        "hex":"#e07e3c"
    },
    {
        "pantone":"7578-c",
        "hex":"#dc6b2f"
    },
    {
        "pantone":"7579-c",
        "hex":"#dc582a"
    },
    {
        "pantone":"7580-c",
        "hex":"#c05131"
    },
    {
        "pantone":"7581-c",
        "hex":"#864a33"
    },
    {
        "pantone":"7582-c",
        "hex":"#674736"
    },
    {
        "pantone":"1625-c",
        "hex":"#ffa38b"
    },
    {
        "pantone":"1635-c",
        "hex":"#ff8d6d"
    },
    {
        "pantone":"1645-c",
        "hex":"#ff6a39"
    },
    {
        "pantone":"1655-c",
        "hex":"#fc4c02"
    },
    {
        "pantone":"1665-c",
        "hex":"#dc4405"
    },
    {
        "pantone":"1675-c",
        "hex":"#a9431e"
    },
    {
        "pantone":"1685-c",
        "hex":"#833921"
    },
    {
        "pantone":"169-c",
        "hex":"#ffb3ab"
    },
    {
        "pantone":"170-c",
        "hex":"#ff8674"
    },
    {
        "pantone":"171-c",
        "hex":"#ff5c39"
    },
    {
        "pantone":"172-c",
        "hex":"#fa4616"
    },
    {
        "pantone":"173-c",
        "hex":"#cf4520"
    },
    {
        "pantone":"174-c",
        "hex":"#963821"
    },
    {
        "pantone":"175-c",
        "hex":"#6b3529"
    },
    {
        "pantone":"7583-c",
        "hex":"#c4622d"
    },
    {
        "pantone":"7584-c",
        "hex":"#ba5826"
    },
    {
        "pantone":"7585-c",
        "hex":"#af5c37"
    },
    {
        "pantone":"7586-c",
        "hex":"#9e5330"
    },
    {
        "pantone":"7587-c",
        "hex":"#924c2e"
    },
    {
        "pantone":"7588-c",
        "hex":"#7b4d35"
    },
    {
        "pantone":"7589-c",
        "hex":"#5c4738"
    },
    {
        "pantone":"7590-c",
        "hex":"#d4b59e"
    },
    {
        "pantone":"7591-c",
        "hex":"#c07d59"
    },
    {
        "pantone":"7592-c",
        "hex":"#b15533"
    },
    {
        "pantone":"7593-c",
        "hex":"#9d432c"
    },
    {
        "pantone":"7594-c",
        "hex":"#7c3a2d"
    },
    {
        "pantone":"7595-c",
        "hex":"#6b3d2e"
    },
    {
        "pantone":"7596-c",
        "hex":"#5c3d31"
    },
    {
        "pantone":"7597-c",
        "hex":"#d14124"
    },
    {
        "pantone":"7598-c",
        "hex":"#bd472a"
    },
    {
        "pantone":"7599-c",
        "hex":"#b33d26"
    },
    {
        "pantone":"7600-c",
        "hex":"#8d3f2b"
    },
    {
        "pantone":"7601-c",
        "hex":"#83412c"
    },
    {
        "pantone":"7602-c",
        "hex":"#7b4931"
    },
    {
        "pantone":"7603-c",
        "hex":"#674230"
    },
    {
        "pantone":"7604-c",
        "hex":"#e4d5d3"
    },
    {
        "pantone":"7605-c",
        "hex":"#e1bbb4"
    },
    {
        "pantone":"7606-c",
        "hex":"#d6938a"
    },
    {
        "pantone":"7607-c",
        "hex":"#c26e60"
    },
    {
        "pantone":"7608-c",
        "hex":"#a4493d"
    },
    {
        "pantone":"7609-c",
        "hex":"#823b34"
    },
    {
        "pantone":"7610-c",
        "hex":"#683431"
    },
    {
        "pantone":"7611-c",
        "hex":"#ddbcb0"
    },
    {
        "pantone":"7612-c",
        "hex":"#ca9a8e"
    },
    {
        "pantone":"7613-c",
        "hex":"#bc8a7e"
    },
    {
        "pantone":"7614-c",
        "hex":"#a37f74"
    },
    {
        "pantone":"7615-c",
        "hex":"#866761"
    },
    {
        "pantone":"7616-c",
        "hex":"#6b4c4c"
    },
    {
        "pantone":"7617-c",
        "hex":"#583d3e"
    },
    {
        "pantone":"7520-c",
        "hex":"#eabeb0"
    },
    {
        "pantone":"7521-c",
        "hex":"#c09c83"
    },
    {
        "pantone":"7522-c",
        "hex":"#b46a55"
    },
    {
        "pantone":"7523-c",
        "hex":"#ab5c57"
    },
    {
        "pantone":"7524-c",
        "hex":"#a45248"
    },
    {
        "pantone":"7525-c",
        "hex":"#9a6a4f"
    },
    {
        "pantone":"7526-c",
        "hex":"#8a391b"
    },
    {
        "pantone":"489-c",
        "hex":"#ecc3b2"
    },
    {
        "pantone":"488-c",
        "hex":"#ecbaa8"
    },
    {
        "pantone":"487-c",
        "hex":"#eaa794"
    },
    {
        "pantone":"486-c",
        "hex":"#e8927c"
    },
    {
        "pantone":"485-c",
        "hex":"#da291c"
    },
    {
        "pantone":"484-c",
        "hex":"#9a3324"
    },
    {
        "pantone":"483-c",
        "hex":"#653024"
    },
    {
        "pantone":"176-c",
        "hex":"#ffb1bb"
    },
    {
        "pantone":"177-c",
        "hex":"#ff808b"
    },
    {
        "pantone":"178-c",
        "hex":"#ff585d"
    },
    {
        "pantone":"179-c",
        "hex":"#e03c31"
    },
    {
        "pantone":"180-c",
        "hex":"#be3a34"
    },
    {
        "pantone":"181-c",
        "hex":"#81312f"
    },
    {
        "pantone":"1765-c",
        "hex":"#ffa3b5"
    },
    {
        "pantone":"1775-c",
        "hex":"#ff8da1"
    },
    {
        "pantone":"1785-c",
        "hex":"#f8485e"
    },
    {
        "pantone":"1788-c",
        "hex":"#ee2737"
    },
    {
        "pantone":"1795-c",
        "hex":"#d22630"
    },
    {
        "pantone":"1805-c",
        "hex":"#af272f"
    },
    {
        "pantone":"1815-c",
        "hex":"#7c2529"
    },
    {
        "pantone":"1767-c",
        "hex":"#fcafc0"
    },
    {
        "pantone":"1777-c",
        "hex":"#fb637e"
    },
    {
        "pantone":"1787-c",
        "hex":"#f4364c"
    },
    {
        "pantone":"1797-c",
        "hex":"#cb333b"
    },
    {
        "pantone":"1807-c",
        "hex":"#a4343a"
    },
    {
        "pantone":"1817-c",
        "hex":"#643335"
    },
    {
        "pantone":"7618-c",
        "hex":"#c66e4e"
    },
    {
        "pantone":"7619-c",
        "hex":"#c04c36"
    },
    {
        "pantone":"7620-c",
        "hex":"#b7312c"
    },
    {
        "pantone":"7621-c",
        "hex":"#ab2328"
    },
    {
        "pantone":"7622-c",
        "hex":"#93272c"
    },
    {
        "pantone":"7623-c",
        "hex":"#8a2a2b"
    },
    {
        "pantone":"7624-c",
        "hex":"#802f2d"
    },
    {
        "pantone":"7625-c",
        "hex":"#e1523d"
    },
    {
        "pantone":"7626-c",
        "hex":"#c63527"
    },
    {
        "pantone":"7627-c",
        "hex":"#a72b2a"
    },
    {
        "pantone":"7628-c",
        "hex":"#9e2a2b"
    },
    {
        "pantone":"7629-c",
        "hex":"#6d3332"
    },
    {
        "pantone":"7630-c",
        "hex":"#633231"
    },
    {
        "pantone":"7631-c",
        "hex":"#572d2d"
    },
    {
        "pantone":"7415-c",
        "hex":"#e6baa8"
    },
    {
        "pantone":"7416-c",
        "hex":"#e56a54"
    },
    {
        "pantone":"7417-c",
        "hex":"#e04e39"
    },
    {
        "pantone":"7418-c",
        "hex":"#cd545b"
    },
    {
        "pantone":"7419-c",
        "hex":"#b04a5a"
    },
    {
        "pantone":"7420-c",
        "hex":"#9b2242"
    },
    {
        "pantone":"7421-c",
        "hex":"#651d32"
    },
    {
        "pantone":"182-c",
        "hex":"#fabbcb"
    },
    {
        "pantone":"183-c",
        "hex":"#fc9bb3"
    },
    {
        "pantone":"184-c",
        "hex":"#f65275"
    },
    {
        "pantone":"185-c",
        "hex":"#e4002b"
    },
    {
        "pantone":"186-c",
        "hex":"#c8102e"
    },
    {
        "pantone":"187-c",
        "hex":"#a6192e"
    },
    {
        "pantone":"188-c",
        "hex":"#76232f"
    },
    {
        "pantone":"196-c",
        "hex":"#ecc7cd"
    },
    {
        "pantone":"197-c",
        "hex":"#e89cae"
    },
    {
        "pantone":"198-c",
        "hex":"#df4661"
    },
    {
        "pantone":"199-c",
        "hex":"#d50032"
    },
    {
        "pantone":"200-c",
        "hex":"#ba0c2f"
    },
    {
        "pantone":"201-c",
        "hex":"#9d2235"
    },
    {
        "pantone":"202-c",
        "hex":"#862633"
    },
    {
        "pantone":"189-c",
        "hex":"#f8a3bc"
    },
    {
        "pantone":"190-c",
        "hex":"#f67599"
    },
    {
        "pantone":"191-c",
        "hex":"#ef426f"
    },
    {
        "pantone":"192-c",
        "hex":"#e40046"
    },
    {
        "pantone":"193-c",
        "hex":"#bf0d3e"
    },
    {
        "pantone":"194-c",
        "hex":"#9b2743"
    },
    {
        "pantone":"195-c",
        "hex":"#782f40"
    },
    {
        "pantone":"1895-c",
        "hex":"#f5b6cd"
    },
    {
        "pantone":"1905-c",
        "hex":"#f59bbb"
    },
    {
        "pantone":"1915-c",
        "hex":"#ef4a81"
    },
    {
        "pantone":"1925-c",
        "hex":"#e0004d"
    },
    {
        "pantone":"1935-c",
        "hex":"#c5003e"
    },
    {
        "pantone":"1945-c",
        "hex":"#a6093d"
    },
    {
        "pantone":"1955-c",
        "hex":"#8a1538"
    },
    {
        "pantone":"705-c",
        "hex":"#f5dadf"
    },
    {
        "pantone":"706-c",
        "hex":"#f7ced7"
    },
    {
        "pantone":"707-c",
        "hex":"#f9b5c4"
    },
    {
        "pantone":"708-c",
        "hex":"#f890a5"
    },
    {
        "pantone":"709-c",
        "hex":"#ef6079"
    },
    {
        "pantone":"710-c",
        "hex":"#e03e52"
    },
    {
        "pantone":"711-c",
        "hex":"#cb2c30"
    },
    {
        "pantone":"698-c",
        "hex":"#f2d4d7"
    },
    {
        "pantone":"699-c",
        "hex":"#f4c3cc"
    },
    {
        "pantone":"700-c",
        "hex":"#f2acb9"
    },
    {
        "pantone":"701-c",
        "hex":"#e68699"
    },
    {
        "pantone":"702-c",
        "hex":"#d25b73"
    },
    {
        "pantone":"703-c",
        "hex":"#b83a4b"
    },
    {
        "pantone":"704-c",
        "hex":"#9e2a2f"
    },
    {
        "pantone":"203-c",
        "hex":"#ecb3cb"
    },
    {
        "pantone":"204-c",
        "hex":"#e782a9"
    },
    {
        "pantone":"205-c",
        "hex":"#e0457b"
    },
    {
        "pantone":"206-c",
        "hex":"#ce0037"
    },
    {
        "pantone":"207-c",
        "hex":"#a50034"
    },
    {
        "pantone":"208-c",
        "hex":"#861f41"
    },
    {
        "pantone":"209-c",
        "hex":"#6f263d"
    },
    {
        "pantone":"210-c",
        "hex":"#f99fc9"
    },
    {
        "pantone":"211-c",
        "hex":"#f57eb6"
    },
    {
        "pantone":"212-c",
        "hex":"#f04e98"
    },
    {
        "pantone":"213-c",
        "hex":"#e31c79"
    },
    {
        "pantone":"214-c",
        "hex":"#ce0f69"
    },
    {
        "pantone":"215-c",
        "hex":"#ac145a"
    },
    {
        "pantone":"216-c",
        "hex":"#7d2248"
    },
    {
        "pantone":"7422-c",
        "hex":"#f4cdd4"
    },
    {
        "pantone":"7423-c",
        "hex":"#e06287"
    },
    {
        "pantone":"7424-c",
        "hex":"#e24585"
    },
    {
        "pantone":"7425-c",
        "hex":"#b52555"
    },
    {
        "pantone":"7426-c",
        "hex":"#a4123f"
    },
    {
        "pantone":"7427-c",
        "hex":"#971b2f"
    },
    {
        "pantone":"7428-c",
        "hex":"#6a2c3e"
    },
    {
        "pantone":"7632-c",
        "hex":"#d6c9ca"
    },
    {
        "pantone":"7633-c",
        "hex":"#c4a4a7"
    },
    {
        "pantone":"7634-c",
        "hex":"#c16784"
    },
    {
        "pantone":"7635-c",
        "hex":"#c63663"
    },
    {
        "pantone":"7636-c",
        "hex":"#bc204b"
    },
    {
        "pantone":"7637-c",
        "hex":"#912f46"
    },
    {
        "pantone":"7638-c",
        "hex":"#7e2d40"
    },
    {
        "pantone":"217-c",
        "hex":"#eabedb"
    },
    {
        "pantone":"218-c",
        "hex":"#e56db1"
    },
    {
        "pantone":"219-c",
        "hex":"#da1884"
    },
    {
        "pantone":"220-c",
        "hex":"#a50050"
    },
    {
        "pantone":"221-c",
        "hex":"#910048"
    },
    {
        "pantone":"222-c",
        "hex":"#6c1d45"
    },
    {
        "pantone":"7639-c",
        "hex":"#936d73"
    },
    {
        "pantone":"7640-c",
        "hex":"#934054"
    },
    {
        "pantone":"7641-c",
        "hex":"#8e2c48"
    },
    {
        "pantone":"7642-c",
        "hex":"#732e4a"
    },
    {
        "pantone":"7643-c",
        "hex":"#672e45"
    },
    {
        "pantone":"7644-c",
        "hex":"#582d40"
    },
    {
        "pantone":"7645-c",
        "hex":"#502b3a"
    },
    {
        "pantone":"223-c",
        "hex":"#ef95cf"
    },
    {
        "pantone":"224-c",
        "hex":"#eb6fbd"
    },
    {
        "pantone":"225-c",
        "hex":"#df1995"
    },
    {
        "pantone":"226-c",
        "hex":"#d0006f"
    },
    {
        "pantone":"227-c",
        "hex":"#aa0061"
    },
    {
        "pantone":"228-c",
        "hex":"#890c58"
    },
    {
        "pantone":"229-c",
        "hex":"#672146"
    },
    {
        "pantone":"230-c",
        "hex":"#f4a6d7"
    },
    {
        "pantone":"231-c",
        "hex":"#f277c6"
    },
    {
        "pantone":"232-c",
        "hex":"#e93cac"
    },
    {
        "pantone":"233-c",
        "hex":"#c6007e"
    },
    {
        "pantone":"234-c",
        "hex":"#a20067"
    },
    {
        "pantone":"235-c",
        "hex":"#840b55"
    },
    {
        "pantone":"670-c",
        "hex":"#ead3e2"
    },
    {
        "pantone":"671-c",
        "hex":"#e6bcd8"
    },
    {
        "pantone":"672-c",
        "hex":"#dfa0c9"
    },
    {
        "pantone":"673-c",
        "hex":"#d986ba"
    },
    {
        "pantone":"674-c",
        "hex":"#c6579a"
    },
    {
        "pantone":"675-c",
        "hex":"#ae2573"
    },
    {
        "pantone":"676-c",
        "hex":"#960051"
    },
    {
        "pantone":"677-c",
        "hex":"#e5cedb"
    },
    {
        "pantone":"678-c",
        "hex":"#e3c8d8"
    },
    {
        "pantone":"679-c",
        "hex":"#debed2"
    },
    {
        "pantone":"680-c",
        "hex":"#c996b6"
    },
    {
        "pantone":"681-c",
        "hex":"#b06c96"
    },
    {
        "pantone":"682-c",
        "hex":"#994878"
    },
    {
        "pantone":"683-c",
        "hex":"#7c2855"
    },
    {
        "pantone":"684-c",
        "hex":"#e4c6d4"
    },
    {
        "pantone":"685-c",
        "hex":"#dcb6c9"
    },
    {
        "pantone":"686-c",
        "hex":"#d0a1ba"
    },
    {
        "pantone":"687-c",
        "hex":"#be84a3"
    },
    {
        "pantone":"688-c",
        "hex":"#a76389"
    },
    {
        "pantone":"689-c",
        "hex":"#893b67"
    },
    {
        "pantone":"690-c",
        "hex":"#612141"
    },
    {
        "pantone":"510-c",
        "hex":"#ebbecb"
    },
    {
        "pantone":"509-c",
        "hex":"#e8b3c3"
    },
    {
        "pantone":"508-c",
        "hex":"#e4a9bb"
    },
    {
        "pantone":"507-c",
        "hex":"#d592aa"
    },
    {
        "pantone":"506-c",
        "hex":"#84344e"
    },
    {
        "pantone":"505-c",
        "hex":"#6f2c3f"
    },
    {
        "pantone":"504-c",
        "hex":"#572932"
    },
    {
        "pantone":"7429-c",
        "hex":"#e2bccb"
    },
    {
        "pantone":"7430-c",
        "hex":"#dca9bf"
    },
    {
        "pantone":"7431-c",
        "hex":"#c9809e"
    },
    {
        "pantone":"7432-c",
        "hex":"#b55c80"
    },
    {
        "pantone":"7433-c",
        "hex":"#a73a64"
    },
    {
        "pantone":"7434-c",
        "hex":"#9b3259"
    },
    {
        "pantone":"7435-c",
        "hex":"#872651"
    },
    {
        "pantone":"691-c",
        "hex":"#e9cdd0"
    },
    {
        "pantone":"692-c",
        "hex":"#e4bec3"
    },
    {
        "pantone":"693-c",
        "hex":"#d7a3ab"
    },
    {
        "pantone":"694-c",
        "hex":"#c48490"
    },
    {
        "pantone":"695-c",
        "hex":"#b46b7a"
    },
    {
        "pantone":"696-c",
        "hex":"#984856"
    },
    {
        "pantone":"697-c",
        "hex":"#893c47"
    },
    {
        "pantone":"496-c",
        "hex":"#f2c6cf"
    },
    {
        "pantone":"495-c",
        "hex":"#f1bdc8"
    },
    {
        "pantone":"494-c",
        "hex":"#e9a2b2"
    },
    {
        "pantone":"493-c",
        "hex":"#dc8699"
    },
    {
        "pantone":"492-c",
        "hex":"#8f3237"
    },
    {
        "pantone":"491-c",
        "hex":"#7f3035"
    },
    {
        "pantone":"490-c",
        "hex":"#5d2a2c"
    },
    {
        "pantone":"503-c",
        "hex":"#e9c4c7"
    },
    {
        "pantone":"502-c",
        "hex":"#e5bac1"
    },
    {
        "pantone":"501-c",
        "hex":"#daa5ad"
    },
    {
        "pantone":"500-c",
        "hex":"#c6858f"
    },
    {
        "pantone":"499-c",
        "hex":"#7a3e3a"
    },
    {
        "pantone":"498-c",
        "hex":"#6a3735"
    },
    {
        "pantone":"497-c",
        "hex":"#512f2e"
    },
    {
        "pantone":"5035-c",
        "hex":"#dfc2c3"
    },
    {
        "pantone":"5025-c",
        "hex":"#dbb7bb"
    },
    {
        "pantone":"5015-c",
        "hex":"#cca1a6"
    },
    {
        "pantone":"5005-c",
        "hex":"#b07c83"
    },
    {
        "pantone":"4995-c",
        "hex":"#9c6169"
    },
    {
        "pantone":"4985-c",
        "hex":"#874b52"
    },
    {
        "pantone":"4975-c",
        "hex":"#3f2021"
    },
    {
        "pantone":"236-c",
        "hex":"#f1a7dc"
    },
    {
        "pantone":"237-c",
        "hex":"#ec86d0"
    },
    {
        "pantone":"238-c",
        "hex":"#e45dbf"
    },
    {
        "pantone":"239-c",
        "hex":"#db3eb1"
    },
    {
        "pantone":"240-c",
        "hex":"#c5299b"
    },
    {
        "pantone":"241-c",
        "hex":"#af1685"
    },
    {
        "pantone":"242-c",
        "hex":"#80225f"
    },
    {
        "pantone":"2365-c",
        "hex":"#efbae1"
    },
    {
        "pantone":"2375-c",
        "hex":"#e277cd"
    },
    {
        "pantone":"2385-c",
        "hex":"#d539b5"
    },
    {
        "pantone":"2395-c",
        "hex":"#c800a1"
    },
    {
        "pantone":"2405-c",
        "hex":"#b0008e"
    },
    {
        "pantone":"2415-c",
        "hex":"#9e007e"
    },
    {
        "pantone":"2425-c",
        "hex":"#830065"
    },
    {
        "pantone":"243-c",
        "hex":"#eab8e4"
    },
    {
        "pantone":"244-c",
        "hex":"#e59bdc"
    },
    {
        "pantone":"245-c",
        "hex":"#dd7fd3"
    },
    {
        "pantone":"246-c",
        "hex":"#c724b1"
    },
    {
        "pantone":"247-c",
        "hex":"#bb16a3"
    },
    {
        "pantone":"248-c",
        "hex":"#a51890"
    },
    {
        "pantone":"249-c",
        "hex":"#80276c"
    },
    {
        "pantone":"7646-c",
        "hex":"#a56e87"
    },
    {
        "pantone":"7647-c",
        "hex":"#a83d72"
    },
    {
        "pantone":"7648-c",
        "hex":"#991e66"
    },
    {
        "pantone":"7649-c",
        "hex":"#8a1b61"
    },
    {
        "pantone":"7650-c",
        "hex":"#722257"
    },
    {
        "pantone":"7651-c",
        "hex":"#6a2a5b"
    },
    {
        "pantone":"7652-c",
        "hex":"#5e2751"
    },
    {
        "pantone":"250-c",
        "hex":"#e7bae4"
    },
    {
        "pantone":"251-c",
        "hex":"#dd9cdf"
    },
    {
        "pantone":"252-c",
        "hex":"#c964cf"
    },
    {
        "pantone":"253-c",
        "hex":"#ad1aac"
    },
    {
        "pantone":"254-c",
        "hex":"#981d97"
    },
    {
        "pantone":"255-c",
        "hex":"#72246c"
    },
    {
        "pantone":"517-c",
        "hex":"#ebc6df"
    },
    {
        "pantone":"516-c",
        "hex":"#e6bedd"
    },
    {
        "pantone":"515-c",
        "hex":"#e2acd7"
    },
    {
        "pantone":"514-c",
        "hex":"#d48bc8"
    },
    {
        "pantone":"513-c",
        "hex":"#93328e"
    },
    {
        "pantone":"512-c",
        "hex":"#833177"
    },
    {
        "pantone":"511-c",
        "hex":"#612c51"
    },
    {
        "pantone":"7436-c",
        "hex":"#eedaea"
    },
    {
        "pantone":"7437-c",
        "hex":"#ccaed0"
    },
    {
        "pantone":"7438-c",
        "hex":"#d59ed7"
    },
    {
        "pantone":"7439-c",
        "hex":"#b288b9"
    },
    {
        "pantone":"7440-c",
        "hex":"#a277a6"
    },
    {
        "pantone":"7441-c",
        "hex":"#9f5cc0"
    },
    {
        "pantone":"7442-c",
        "hex":"#963cbd"
    },
    {
        "pantone":"2562-c",
        "hex":"#d7a9e3"
    },
    {
        "pantone":"2572-c",
        "hex":"#c98bdb"
    },
    {
        "pantone":"2582-c",
        "hex":"#ac4fc6"
    },
    {
        "pantone":"2592-c",
        "hex":"#9b26b6"
    },
    {
        "pantone":"2602-c",
        "hex":"#87189d"
    },
    {
        "pantone":"2612-c",
        "hex":"#772583"
    },
    {
        "pantone":"2622-c",
        "hex":"#653165"
    },
    {
        "pantone":"7653-c",
        "hex":"#948794"
    },
    {
        "pantone":"7654-c",
        "hex":"#a2789c"
    },
    {
        "pantone":"7655-c",
        "hex":"#a15a95"
    },
    {
        "pantone":"7656-c",
        "hex":"#8e3a80"
    },
    {
        "pantone":"7657-c",
        "hex":"#6e2b62"
    },
    {
        "pantone":"7658-c",
        "hex":"#6a3460"
    },
    {
        "pantone":"7659-c",
        "hex":"#5d3754"
    },
    {
        "pantone":"524-c",
        "hex":"#d5c2d8"
    },
    {
        "pantone":"523-c",
        "hex":"#c9b1d0"
    },
    {
        "pantone":"522-c",
        "hex":"#ba9cc5"
    },
    {
        "pantone":"521-c",
        "hex":"#a57fb2"
    },
    {
        "pantone":"520-c",
        "hex":"#642f6c"
    },
    {
        "pantone":"519-c",
        "hex":"#59315f"
    },
    {
        "pantone":"518-c",
        "hex":"#4b3048"
    },
    {
        "pantone":"5245-c",
        "hex":"#dbcdd3"
    },
    {
        "pantone":"5235-c",
        "hex":"#d0bec7"
    },
    {
        "pantone":"5225-c",
        "hex":"#c6b0bc"
    },
    {
        "pantone":"5215-c",
        "hex":"#af95a6"
    },
    {
        "pantone":"5205-c",
        "hex":"#86647a"
    },
    {
        "pantone":"5195-c",
        "hex":"#66435a"
    },
    {
        "pantone":"5185-c",
        "hex":"#4a3041"
    },
    {
        "pantone":"5175-c",
        "hex":"#d8c8d1"
    },
    {
        "pantone":"5165-c",
        "hex":"#d3c0cd"
    },
    {
        "pantone":"5155-c",
        "hex":"#bfa5b8"
    },
    {
        "pantone":"5145-c",
        "hex":"#9b7793"
    },
    {
        "pantone":"5135-c",
        "hex":"#7e5475"
    },
    {
        "pantone":"5125-c",
        "hex":"#693c5e"
    },
    {
        "pantone":"5115-c",
        "hex":"#512a44"
    },
    {
        "pantone":"531-c",
        "hex":"#dfc8e7"
    },
    {
        "pantone":"530-c",
        "hex":"#d7b9e4"
    },
    {
        "pantone":"529-c",
        "hex":"#caa2dd"
    },
    {
        "pantone":"528-c",
        "hex":"#b580d1"
    },
    {
        "pantone":"527-c",
        "hex":"#8031a7"
    },
    {
        "pantone":"526-c",
        "hex":"#702f8a"
    },
    {
        "pantone":"525-c",
        "hex":"#572c5f"
    },
    {
        "pantone":"256-c",
        "hex":"#d6bfdd"
    },
    {
        "pantone":"257-c",
        "hex":"#c6a1cf"
    },
    {
        "pantone":"258-c",
        "hex":"#8c4799"
    },
    {
        "pantone":"259-c",
        "hex":"#6d2077"
    },
    {
        "pantone":"260-c",
        "hex":"#642667"
    },
    {
        "pantone":"261-c",
        "hex":"#5d285f"
    },
    {
        "pantone":"262-c",
        "hex":"#51284f"
    },
    {
        "pantone":"2563-c",
        "hex":"#cba3d8"
    },
    {
        "pantone":"2573-c",
        "hex":"#b884cb"
    },
    {
        "pantone":"2583-c",
        "hex":"#a05eb5"
    },
    {
        "pantone":"2593-c",
        "hex":"#84329b"
    },
    {
        "pantone":"2603-c",
        "hex":"#702082"
    },
    {
        "pantone":"2613-c",
        "hex":"#671e75"
    },
    {
        "pantone":"2623-c",
        "hex":"#5f2167"
    },
    {
        "pantone":"7660-c",
        "hex":"#9991a4"
    },
    {
        "pantone":"7661-c",
        "hex":"#8d6e97"
    },
    {
        "pantone":"7662-c",
        "hex":"#7a4183"
    },
    {
        "pantone":"7663-c",
        "hex":"#6b3077"
    },
    {
        "pantone":"7664-c",
        "hex":"#653279"
    },
    {
        "pantone":"7665-c",
        "hex":"#5e366e"
    },
    {
        "pantone":"7666-c",
        "hex":"#5c4e63"
    },
    {
        "pantone":"2567-c",
        "hex":"#c1a0da"
    },
    {
        "pantone":"2577-c",
        "hex":"#a77bca"
    },
    {
        "pantone":"2587-c",
        "hex":"#8246af"
    },
    {
        "pantone":"2597-c",
        "hex":"#5c068c"
    },
    {
        "pantone":"2607-c",
        "hex":"#500778"
    },
    {
        "pantone":"2617-c",
        "hex":"#470a68"
    },
    {
        "pantone":"2627-c",
        "hex":"#3c1053"
    },
    {
        "pantone":"263-c",
        "hex":"#d7c6e6"
    },
    {
        "pantone":"264-c",
        "hex":"#c1a7e2"
    },
    {
        "pantone":"265-c",
        "hex":"#9063cd"
    },
    {
        "pantone":"266-c",
        "hex":"#753bbd"
    },
    {
        "pantone":"267-c",
        "hex":"#5f259f"
    },
    {
        "pantone":"268-c",
        "hex":"#582c83"
    },
    {
        "pantone":"269-c",
        "hex":"#512d6d"
    },
    {
        "pantone":"2635-c",
        "hex":"#c5b4e3"
    },
    {
        "pantone":"2645-c",
        "hex":"#ad96dc"
    },
    {
        "pantone":"2655-c",
        "hex":"#9678d3"
    },
    {
        "pantone":"2665-c",
        "hex":"#7d55c7"
    },
    {
        "pantone":"2685-c",
        "hex":"#330072"
    },
    {
        "pantone":"2695-c",
        "hex":"#2e1a47"
    },
    {
        "pantone":"270-c",
        "hex":"#b4b5df"
    },
    {
        "pantone":"271-c",
        "hex":"#9595d2"
    },
    {
        "pantone":"272-c",
        "hex":"#7474c1"
    },
    {
        "pantone":"273-c",
        "hex":"#24135f"
    },
    {
        "pantone":"274-c",
        "hex":"#211551"
    },
    {
        "pantone":"275-c",
        "hex":"#201747"
    },
    {
        "pantone":"276-c",
        "hex":"#221c35"
    },
    {
        "pantone":"2705-c",
        "hex":"#a7a4e0"
    },
    {
        "pantone":"2715-c",
        "hex":"#8b84d7"
    },
    {
        "pantone":"2725-c",
        "hex":"#685bc7"
    },
    {
        "pantone":"2735-c",
        "hex":"#2e008b"
    },
    {
        "pantone":"2745-c",
        "hex":"#280071"
    },
    {
        "pantone":"2755-c",
        "hex":"#250e62"
    },
    {
        "pantone":"2765-c",
        "hex":"#201547"
    },
    {
        "pantone":"7667-c",
        "hex":"#6e7ca0"
    },
    {
        "pantone":"7668-c",
        "hex":"#686e9f"
    },
    {
        "pantone":"7669-c",
        "hex":"#615e9b"
    },
    {
        "pantone":"7670-c",
        "hex":"#565294"
    },
    {
        "pantone":"7671-c",
        "hex":"#514689"
    },
    {
        "pantone":"7672-c",
        "hex":"#4c4184"
    },
    {
        "pantone":"7673-c",
        "hex":"#535486"
    },
    {
        "pantone":"7443-c",
        "hex":"#dddae8"
    },
    {
        "pantone":"7444-c",
        "hex":"#b6b8dc"
    },
    {
        "pantone":"7445-c",
        "hex":"#a7a2c3"
    },
    {
        "pantone":"7446-c",
        "hex":"#8986ca"
    },
    {
        "pantone":"7447-c",
        "hex":"#5d4777"
    },
    {
        "pantone":"7448-c",
        "hex":"#4b384c"
    },
    {
        "pantone":"7449-c",
        "hex":"#41273b"
    },
    {
        "pantone":"7674-c",
        "hex":"#878cb4"
    },
    {
        "pantone":"7675-c",
        "hex":"#7c7fab"
    },
    {
        "pantone":"7676-c",
        "hex":"#7566a0"
    },
    {
        "pantone":"7677-c",
        "hex":"#6f5091"
    },
    {
        "pantone":"7678-c",
        "hex":"#68478d"
    },
    {
        "pantone":"7679-c",
        "hex":"#563d82"
    },
    {
        "pantone":"7680-c",
        "hex":"#523178"
    },
    {
        "pantone":"663-c",
        "hex":"#e5e1e6"
    },
    {
        "pantone":"664-c",
        "hex":"#e0dbe3"
    },
    {
        "pantone":"665-c",
        "hex":"#c6bcd0"
    },
    {
        "pantone":"666-c",
        "hex":"#a192b2"
    },
    {
        "pantone":"667-c",
        "hex":"#7c6992"
    },
    {
        "pantone":"668-c",
        "hex":"#614b79"
    },
    {
        "pantone":"669-c",
        "hex":"#3f2a56"
    },
    {
        "pantone":"5315-c",
        "hex":"#d8d7df"
    },
    {
        "pantone":"5305-c",
        "hex":"#c6c4d2"
    },
    {
        "pantone":"5295-c",
        "hex":"#b3b0c4"
    },
    {
        "pantone":"5285-c",
        "hex":"#8d89a5"
    },
    {
        "pantone":"5275-c",
        "hex":"#595478"
    },
    {
        "pantone":"5265-c",
        "hex":"#403a60"
    },
    {
        "pantone":"5255-c",
        "hex":"#1e1a34"
    },
    {
        "pantone":"538-c",
        "hex":"#c5cfda"
    },
    {
        "pantone":"537-c",
        "hex":"#bbc7d6"
    },
    {
        "pantone":"536-c",
        "hex":"#a2b2c8"
    },
    {
        "pantone":"535-c",
        "hex":"#8e9fbc"
    },
    {
        "pantone":"534-c",
        "hex":"#1b365d"
    },
    {
        "pantone":"533-c",
        "hex":"#1f2a44"
    },
    {
        "pantone":"532-c",
        "hex":"#1c1f2a"
    },
    {
        "pantone":"7541-c",
        "hex":"#d9e1e2"
    },
    {
        "pantone":"7542-c",
        "hex":"#a4bcc2"
    },
    {
        "pantone":"7543-c",
        "hex":"#98a4ae"
    },
    {
        "pantone":"7544-c",
        "hex":"#768692"
    },
    {
        "pantone":"7545-c",
        "hex":"#425563"
    },
    {
        "pantone":"7546-c",
        "hex":"#253746"
    },
    {
        "pantone":"7547-c",
        "hex":"#131e29"
    },
    {
        "pantone":"552-c",
        "hex":"#b9d3dc"
    },
    {
        "pantone":"551-c",
        "hex":"#a3c7d2"
    },
    {
        "pantone":"550-c",
        "hex":"#8db9ca"
    },
    {
        "pantone":"549-c",
        "hex":"#6ba4b8"
    },
    {
        "pantone":"548-c",
        "hex":"#003d4c"
    },
    {
        "pantone":"547-c",
        "hex":"#00313c"
    },
    {
        "pantone":"546-c",
        "hex":"#072b31"
    },
    {
        "pantone":"5455-c",
        "hex":"#bfced6"
    },
    {
        "pantone":"5445-c",
        "hex":"#b7c9d3"
    },
    {
        "pantone":"5435-c",
        "hex":"#a6bbc8"
    },
    {
        "pantone":"5425-c",
        "hex":"#7a99ac"
    },
    {
        "pantone":"5415-c",
        "hex":"#5b7f95"
    },
    {
        "pantone":"5405-c",
        "hex":"#4f758b"
    },
    {
        "pantone":"5395-c",
        "hex":"#081f2c"
    },
    {
        "pantone":"642-c",
        "hex":"#d1dde6"
    },
    {
        "pantone":"643-c",
        "hex":"#c6d6e3"
    },
    {
        "pantone":"644-c",
        "hex":"#9bb8d3"
    },
    {
        "pantone":"645-c",
        "hex":"#7da1c4"
    },
    {
        "pantone":"646-c",
        "hex":"#5e8ab4"
    },
    {
        "pantone":"647-c",
        "hex":"#236192"
    },
    {
        "pantone":"648-c",
        "hex":"#002e5d"
    },
    {
        "pantone":"649-c",
        "hex":"#dbe2e9"
    },
    {
        "pantone":"650-c",
        "hex":"#ced9e5"
    },
    {
        "pantone":"651-c",
        "hex":"#a7bcd6"
    },
    {
        "pantone":"652-c",
        "hex":"#7d9bc1"
    },
    {
        "pantone":"653-c",
        "hex":"#326295"
    },
    {
        "pantone":"654-c",
        "hex":"#003a70"
    },
    {
        "pantone":"655-c",
        "hex":"#002554"
    },
    {
        "pantone":"656-c",
        "hex":"#dde5ed"
    },
    {
        "pantone":"657-c",
        "hex":"#c8d8eb"
    },
    {
        "pantone":"658-c",
        "hex":"#b1c9e8"
    },
    {
        "pantone":"659-c",
        "hex":"#7ba4db"
    },
    {
        "pantone":"660-c",
        "hex":"#407ec9"
    },
    {
        "pantone":"661-c",
        "hex":"#003594"
    },
    {
        "pantone":"662-c",
        "hex":"#001a70"
    },
    {
        "pantone":"7450-c",
        "hex":"#bdc5db"
    },
    {
        "pantone":"7451-c",
        "hex":"#89abe3"
    },
    {
        "pantone":"7452-c",
        "hex":"#8094dd"
    },
    {
        "pantone":"7453-c",
        "hex":"#7ba6de"
    },
    {
        "pantone":"7454-c",
        "hex":"#5f8fb4"
    },
    {
        "pantone":"7455-c",
        "hex":"#3a5dae"
    },
    {
        "pantone":"7456-c",
        "hex":"#606eb2"
    },
    {
        "pantone":"2706-c",
        "hex":"#cbd3eb"
    },
    {
        "pantone":"2716-c",
        "hex":"#9faee5"
    },
    {
        "pantone":"2726-c",
        "hex":"#485cc7"
    },
    {
        "pantone":"2736-c",
        "hex":"#1e22aa"
    },
    {
        "pantone":"2746-c",
        "hex":"#171c8f"
    },
    {
        "pantone":"2756-c",
        "hex":"#151f6d"
    },
    {
        "pantone":"2766-c",
        "hex":"#141b4d"
    },
    {
        "pantone":"2708-c",
        "hex":"#b8ccea"
    },
    {
        "pantone":"2718-c",
        "hex":"#5c88da"
    },
    {
        "pantone":"2728-c",
        "hex":"#0047bb"
    },
    {
        "pantone":"2738-c",
        "hex":"#06038d"
    },
    {
        "pantone":"2748-c",
        "hex":"#001871"
    },
    {
        "pantone":"2758-c",
        "hex":"#001e62"
    },
    {
        "pantone":"2768-c",
        "hex":"#071d49"
    },
    {
        "pantone":"2707-c",
        "hex":"#c3d7ee"
    },
    {
        "pantone":"2717-c",
        "hex":"#a7c6ed"
    },
    {
        "pantone":"2727-c",
        "hex":"#307fe2"
    },
    {
        "pantone":"2747-c",
        "hex":"#001a72"
    },
    {
        "pantone":"2757-c",
        "hex":"#001e60"
    },
    {
        "pantone":"2767-c",
        "hex":"#13294b"
    },
    {
        "pantone":"277-c",
        "hex":"#abcae9"
    },
    {
        "pantone":"278-c",
        "hex":"#8bb8e8"
    },
    {
        "pantone":"279-c",
        "hex":"#418fde"
    },
    {
        "pantone":"280-c",
        "hex":"#012169"
    },
    {
        "pantone":"281-c",
        "hex":"#00205b"
    },
    {
        "pantone":"282-c",
        "hex":"#041e42"
    },
    {
        "pantone":"283-c",
        "hex":"#92c1e9"
    },
    {
        "pantone":"284-c",
        "hex":"#6cace4"
    },
    {
        "pantone":"285-c",
        "hex":"#0072ce"
    },
    {
        "pantone":"286-c",
        "hex":"#0033a0"
    },
    {
        "pantone":"287-c",
        "hex":"#003087"
    },
    {
        "pantone":"288-c",
        "hex":"#002d72"
    },
    {
        "pantone":"289-c",
        "hex":"#0c2340"
    },
    {
        "pantone":"7681-c",
        "hex":"#94a9cb"
    },
    {
        "pantone":"7682-c",
        "hex":"#6787b7"
    },
    {
        "pantone":"7683-c",
        "hex":"#426da9"
    },
    {
        "pantone":"7684-c",
        "hex":"#385e9d"
    },
    {
        "pantone":"7685-c",
        "hex":"#2c5697"
    },
    {
        "pantone":"7686-c",
        "hex":"#1d4f91"
    },
    {
        "pantone":"7687-c",
        "hex":"#1d428a"
    },
    {
        "pantone":"545-c",
        "hex":"#c6dae7"
    },
    {
        "pantone":"544-c",
        "hex":"#bdd6e6"
    },
    {
        "pantone":"543-c",
        "hex":"#a4c8e1"
    },
    {
        "pantone":"542-c",
        "hex":"#7bafd4"
    },
    {
        "pantone":"541-c",
        "hex":"#003c71"
    },
    {
        "pantone":"540-c",
        "hex":"#003057"
    },
    {
        "pantone":"539-c",
        "hex":"#00263a"
    },
    {
        "pantone":"290-c",
        "hex":"#b9d9eb"
    },
    {
        "pantone":"291-c",
        "hex":"#9bcbeb"
    },
    {
        "pantone":"292-c",
        "hex":"#69b3e7"
    },
    {
        "pantone":"293-c",
        "hex":"#003da5"
    },
    {
        "pantone":"294-c",
        "hex":"#002f6c"
    },
    {
        "pantone":"295-c",
        "hex":"#002855"
    },
    {
        "pantone":"296-c",
        "hex":"#041c2c"
    },
    {
        "pantone":"2905-c",
        "hex":"#8dc8e8"
    },
    {
        "pantone":"2915-c",
        "hex":"#62b5e5"
    },
    {
        "pantone":"2925-c",
        "hex":"#009cde"
    },
    {
        "pantone":"2935-c",
        "hex":"#0057b8"
    },
    {
        "pantone":"2945-c",
        "hex":"#004c97"
    },
    {
        "pantone":"2955-c",
        "hex":"#003865"
    },
    {
        "pantone":"2965-c",
        "hex":"#00263e"
    },
    {
        "pantone":"297-c",
        "hex":"#71c5e8"
    },
    {
        "pantone":"298-c",
        "hex":"#41b6e6"
    },
    {
        "pantone":"299-c",
        "hex":"#00a3e0"
    },
    {
        "pantone":"300-c",
        "hex":"#005eb8"
    },
    {
        "pantone":"301-c",
        "hex":"#004b87"
    },
    {
        "pantone":"302-c",
        "hex":"#003b5c"
    },
    {
        "pantone":"303-c",
        "hex":"#002a3a"
    },
    {
        "pantone":"7688-c",
        "hex":"#4698cb"
    },
    {
        "pantone":"7689-c",
        "hex":"#298fc2"
    },
    {
        "pantone":"7690-c",
        "hex":"#0076a8"
    },
    {
        "pantone":"7691-c",
        "hex":"#006298"
    },
    {
        "pantone":"7692-c",
        "hex":"#005587"
    },
    {
        "pantone":"7693-c",
        "hex":"#004976"
    },
    {
        "pantone":"7694-c",
        "hex":"#01426a"
    },
    {
        "pantone":"2975-c",
        "hex":"#99d6ea"
    },
    {
        "pantone":"2985-c",
        "hex":"#5bc2e7"
    },
    {
        "pantone":"2995-c",
        "hex":"#00a9e0"
    },
    {
        "pantone":"3005-c",
        "hex":"#0077c8"
    },
    {
        "pantone":"3015-c",
        "hex":"#00629b"
    },
    {
        "pantone":"3025-c",
        "hex":"#004f71"
    },
    {
        "pantone":"3035-c",
        "hex":"#003e51"
    },
    {
        "pantone":"7695-c",
        "hex":"#7ba7bc"
    },
    {
        "pantone":"7696-c",
        "hex":"#6399ae"
    },
    {
        "pantone":"7697-c",
        "hex":"#4e87a0"
    },
    {
        "pantone":"7698-c",
        "hex":"#41748d"
    },
    {
        "pantone":"7699-c",
        "hex":"#34657f"
    },
    {
        "pantone":"7700-c",
        "hex":"#165c7d"
    },
    {
        "pantone":"7701-c",
        "hex":"#005776"
    },
    {
        "pantone":"7457-c",
        "hex":"#bbdde6"
    },
    {
        "pantone":"7458-c",
        "hex":"#71b2c9"
    },
    {
        "pantone":"7459-c",
        "hex":"#4298b5"
    },
    {
        "pantone":"7460-c",
        "hex":"#0086bf"
    },
    {
        "pantone":"7461-c",
        "hex":"#007dba"
    },
    {
        "pantone":"7462-c",
        "hex":"#00558c"
    },
    {
        "pantone":"7463-c",
        "hex":"#002b49"
    },
    {
        "pantone":"304-c",
        "hex":"#9adbe8"
    },
    {
        "pantone":"305-c",
        "hex":"#59cbe8"
    },
    {
        "pantone":"306-c",
        "hex":"#00b5e2"
    },
    {
        "pantone":"307-c",
        "hex":"#006ba6"
    },
    {
        "pantone":"308-c",
        "hex":"#00587c"
    },
    {
        "pantone":"309-c",
        "hex":"#003b49"
    },
    {
        "pantone":"635-c",
        "hex":"#a4dbe8"
    },
    {
        "pantone":"636-c",
        "hex":"#8bd3e6"
    },
    {
        "pantone":"637-c",
        "hex":"#4ec3e0"
    },
    {
        "pantone":"638-c",
        "hex":"#00afd7"
    },
    {
        "pantone":"639-c",
        "hex":"#0095c8"
    },
    {
        "pantone":"640-c",
        "hex":"#0082ba"
    },
    {
        "pantone":"641-c",
        "hex":"#0067a0"
    },
    {
        "pantone":"7702-c",
        "hex":"#48a9c5"
    },
    {
        "pantone":"7703-c",
        "hex":"#009cbd"
    },
    {
        "pantone":"7704-c",
        "hex":"#0085ad"
    },
    {
        "pantone":"7705-c",
        "hex":"#007096"
    },
    {
        "pantone":"7706-c",
        "hex":"#006a8e"
    },
    {
        "pantone":"7707-c",
        "hex":"#00617f"
    },
    {
        "pantone":"7708-c",
        "hex":"#005670"
    },
    {
        "pantone":"628-c",
        "hex":"#b8dde1"
    },
    {
        "pantone":"629-c",
        "hex":"#9bd3dd"
    },
    {
        "pantone":"630-c",
        "hex":"#77c5d5"
    },
    {
        "pantone":"631-c",
        "hex":"#3eb1c8"
    },
    {
        "pantone":"632-c",
        "hex":"#0093b2"
    },
    {
        "pantone":"633-c",
        "hex":"#007396"
    },
    {
        "pantone":"634-c",
        "hex":"#005f83"
    },
    {
        "pantone":"310-c",
        "hex":"#6ad1e3"
    },
    {
        "pantone":"311-c",
        "hex":"#05c3de"
    },
    {
        "pantone":"312-c",
        "hex":"#00a9ce"
    },
    {
        "pantone":"313-c",
        "hex":"#0092bc"
    },
    {
        "pantone":"314-c",
        "hex":"#007fa3"
    },
    {
        "pantone":"315-c",
        "hex":"#00677f"
    },
    {
        "pantone":"316-c",
        "hex":"#004851"
    },
    {
        "pantone":"3105-c",
        "hex":"#68d2df"
    },
    {
        "pantone":"3115-c",
        "hex":"#00c1d5"
    },
    {
        "pantone":"3125-c",
        "hex":"#00aec7"
    },
    {
        "pantone":"3135-c",
        "hex":"#008eaa"
    },
    {
        "pantone":"3145-c",
        "hex":"#00778b"
    },
    {
        "pantone":"3155-c",
        "hex":"#006272"
    },
    {
        "pantone":"3165-c",
        "hex":"#004f59"
    },
    {
        "pantone":"7709-c",
        "hex":"#63b1bc"
    },
    {
        "pantone":"7710-c",
        "hex":"#00a7b5"
    },
    {
        "pantone":"7711-c",
        "hex":"#0097a9"
    },
    {
        "pantone":"7712-c",
        "hex":"#00859b"
    },
    {
        "pantone":"7713-c",
        "hex":"#007d8a"
    },
    {
        "pantone":"7714-c",
        "hex":"#007680"
    },
    {
        "pantone":"7715-c",
        "hex":"#006269"
    },
    {
        "pantone":"317-c",
        "hex":"#b1e4e3"
    },
    {
        "pantone":"318-c",
        "hex":"#88dbdf"
    },
    {
        "pantone":"319-c",
        "hex":"#2dccd3"
    },
    {
        "pantone":"320-c",
        "hex":"#009ca6"
    },
    {
        "pantone":"321-c",
        "hex":"#008c95"
    },
    {
        "pantone":"322-c",
        "hex":"#007377"
    },
    {
        "pantone":"323-c",
        "hex":"#005f61"
    },
    {
        "pantone":"7464-c",
        "hex":"#a0d1ca"
    },
    {
        "pantone":"7465-c",
        "hex":"#40c1ac"
    },
    {
        "pantone":"7466-c",
        "hex":"#00b0b9"
    },
    {
        "pantone":"7467-c",
        "hex":"#00a3ad"
    },
    {
        "pantone":"7468-c",
        "hex":"#007398"
    },
    {
        "pantone":"7469-c",
        "hex":"#005f86"
    },
    {
        "pantone":"7470-c",
        "hex":"#005a70"
    },
    {
        "pantone":"7471-c",
        "hex":"#7eddd3"
    },
    {
        "pantone":"7472-c",
        "hex":"#5cb8b2"
    },
    {
        "pantone":"7473-c",
        "hex":"#279989"
    },
    {
        "pantone":"7474-c",
        "hex":"#007681"
    },
    {
        "pantone":"7475-c",
        "hex":"#487a7b"
    },
    {
        "pantone":"7476-c",
        "hex":"#0d5257"
    },
    {
        "pantone":"7477-c",
        "hex":"#244c5a"
    },
    {
        "pantone":"5523-c",
        "hex":"#b6cfd0"
    },
    {
        "pantone":"5513-c",
        "hex":"#abc7ca"
    },
    {
        "pantone":"5503-c",
        "hex":"#94b7bb"
    },
    {
        "pantone":"5493-c",
        "hex":"#7fa9ae"
    },
    {
        "pantone":"5483-c",
        "hex":"#4f868e"
    },
    {
        "pantone":"5473-c",
        "hex":"#115e67"
    },
    {
        "pantone":"5463-c",
        "hex":"#07272d"
    },
    {
        "pantone":"7716-c",
        "hex":"#00968f"
    },
    {
        "pantone":"7717-c",
        "hex":"#00857d"
    },
    {
        "pantone":"7718-c",
        "hex":"#007672"
    },
    {
        "pantone":"7719-c",
        "hex":"#006d68"
    },
    {
        "pantone":"7720-c",
        "hex":"#00635b"
    },
    {
        "pantone":"7721-c",
        "hex":"#005e5d"
    },
    {
        "pantone":"7722-c",
        "hex":"#005151"
    },
    {
        "pantone":"324-c",
        "hex":"#9cdbd9"
    },
    {
        "pantone":"325-c",
        "hex":"#64ccc9"
    },
    {
        "pantone":"326-c",
        "hex":"#00b2a9"
    },
    {
        "pantone":"327-c",
        "hex":"#008675"
    },
    {
        "pantone":"328-c",
        "hex":"#007367"
    },
    {
        "pantone":"329-c",
        "hex":"#00685e"
    },
    {
        "pantone":"330-c",
        "hex":"#00534c"
    },
    {
        "pantone":"3242-c",
        "hex":"#71dbd4"
    },
    {
        "pantone":"3252-c",
        "hex":"#2ad2c9"
    },
    {
        "pantone":"3262-c",
        "hex":"#00bfb3"
    },
    {
        "pantone":"3272-c",
        "hex":"#00a499"
    },
    {
        "pantone":"3282-c",
        "hex":"#008578"
    },
    {
        "pantone":"3292-c",
        "hex":"#00594f"
    },
    {
        "pantone":"3302-c",
        "hex":"#004c45"
    },
    {
        "pantone":"3245-c",
        "hex":"#7ce0d3"
    },
    {
        "pantone":"3255-c",
        "hex":"#2cd5c4"
    },
    {
        "pantone":"3265-c",
        "hex":"#00c7b1"
    },
    {
        "pantone":"3275-c",
        "hex":"#00b398"
    },
    {
        "pantone":"3285-c",
        "hex":"#009681"
    },
    {
        "pantone":"3295-c",
        "hex":"#007864"
    },
    {
        "pantone":"3305-c",
        "hex":"#004e42"
    },
    {
        "pantone":"3248-c",
        "hex":"#6dcdb8"
    },
    {
        "pantone":"3258-c",
        "hex":"#49c5b1"
    },
    {
        "pantone":"3268-c",
        "hex":"#00ab8e"
    },
    {
        "pantone":"3278-c",
        "hex":"#009b77"
    },
    {
        "pantone":"3288-c",
        "hex":"#008264"
    },
    {
        "pantone":"3298-c",
        "hex":"#006a52"
    },
    {
        "pantone":"3308-c",
        "hex":"#034638"
    },
    {
        "pantone":"566-c",
        "hex":"#b9dcd2"
    },
    {
        "pantone":"565-c",
        "hex":"#a1d6ca"
    },
    {
        "pantone":"564-c",
        "hex":"#86c8bc"
    },
    {
        "pantone":"563-c",
        "hex":"#6bbbae"
    },
    {
        "pantone":"562-c",
        "hex":"#006f62"
    },
    {
        "pantone":"561-c",
        "hex":"#00594c"
    },
    {
        "pantone":"560-c",
        "hex":"#1d3c34"
    },
    {
        "pantone":"573-c",
        "hex":"#b5e3d8"
    },
    {
        "pantone":"572-c",
        "hex":"#a5dfd3"
    },
    {
        "pantone":"571-c",
        "hex":"#98dbce"
    },
    {
        "pantone":"570-c",
        "hex":"#6bcaba"
    },
    {
        "pantone":"569-c",
        "hex":"#00816d"
    },
    {
        "pantone":"568-c",
        "hex":"#006c5b"
    },
    {
        "pantone":"567-c",
        "hex":"#173f35"
    },
    {
        "pantone":"559-c",
        "hex":"#adcab8"
    },
    {
        "pantone":"558-c",
        "hex":"#9abeaa"
    },
    {
        "pantone":"557-c",
        "hex":"#85b09a"
    },
    {
        "pantone":"556-c",
        "hex":"#6fa287"
    },
    {
        "pantone":"555-c",
        "hex":"#28724f"
    },
    {
        "pantone":"554-c",
        "hex":"#205c40"
    },
    {
        "pantone":"553-c",
        "hex":"#284734"
    },
    {
        "pantone":"5595-c",
        "hex":"#bfcec2"
    },
    {
        "pantone":"5585-c",
        "hex":"#a7bdb1"
    },
    {
        "pantone":"5575-c",
        "hex":"#92aca0"
    },
    {
        "pantone":"5565-c",
        "hex":"#7f9c90"
    },
    {
        "pantone":"5555-c",
        "hex":"#5c7f71"
    },
    {
        "pantone":"5545-c",
        "hex":"#43695b"
    },
    {
        "pantone":"5535-c",
        "hex":"#183028"
    },
    {
        "pantone":"5665-c",
        "hex":"#bac5b9"
    },
    {
        "pantone":"5655-c",
        "hex":"#b0bdb0"
    },
    {
        "pantone":"5645-c",
        "hex":"#a3b2a4"
    },
    {
        "pantone":"5635-c",
        "hex":"#94a596"
    },
    {
        "pantone":"5625-c",
        "hex":"#708573"
    },
    {
        "pantone":"5615-c",
        "hex":"#5e7461"
    },
    {
        "pantone":"5605-c",
        "hex":"#22372b"
    },
    {
        "pantone":"5527-c",
        "hex":"#bcc9c5"
    },
    {
        "pantone":"5517-c",
        "hex":"#b1c0bc"
    },
    {
        "pantone":"5507-c",
        "hex":"#9db0ac"
    },
    {
        "pantone":"5497-c",
        "hex":"#829995"
    },
    {
        "pantone":"5487-c",
        "hex":"#5d7975"
    },
    {
        "pantone":"5477-c",
        "hex":"#3e5d58"
    },
    {
        "pantone":"5467-c",
        "hex":"#18332f"
    },
    {
        "pantone":"621-c",
        "hex":"#d1e0d7"
    },
    {
        "pantone":"622-c",
        "hex":"#b7cdc2"
    },
    {
        "pantone":"623-c",
        "hex":"#9ab9ad"
    },
    {
        "pantone":"624-c",
        "hex":"#789f90"
    },
    {
        "pantone":"625-c",
        "hex":"#507f70"
    },
    {
        "pantone":"626-c",
        "hex":"#285c4d"
    },
    {
        "pantone":"627-c",
        "hex":"#13322b"
    },
    {
        "pantone":"331-c",
        "hex":"#a7e6d7"
    },
    {
        "pantone":"332-c",
        "hex":"#8ce2d0"
    },
    {
        "pantone":"333-c",
        "hex":"#3cdbc0"
    },
    {
        "pantone":"334-c",
        "hex":"#009775"
    },
    {
        "pantone":"335-c",
        "hex":"#007b5f"
    },
    {
        "pantone":"336-c",
        "hex":"#00664f"
    },
    {
        "pantone":"337-c",
        "hex":"#8fd6bd"
    },
    {
        "pantone":"338-c",
        "hex":"#6eceb2"
    },
    {
        "pantone":"339-c",
        "hex":"#00b388"
    },
    {
        "pantone":"340-c",
        "hex":"#00965e"
    },
    {
        "pantone":"341-c",
        "hex":"#007a53"
    },
    {
        "pantone":"342-c",
        "hex":"#006747"
    },
    {
        "pantone":"343-c",
        "hex":"#115740"
    },
    {
        "pantone":"7723-c",
        "hex":"#50a684"
    },
    {
        "pantone":"7724-c",
        "hex":"#00966c"
    },
    {
        "pantone":"7725-c",
        "hex":"#008755"
    },
    {
        "pantone":"7726-c",
        "hex":"#007b4b"
    },
    {
        "pantone":"7727-c",
        "hex":"#006f44"
    },
    {
        "pantone":"7728-c",
        "hex":"#006845"
    },
    {
        "pantone":"7729-c",
        "hex":"#005844"
    },
    {
        "pantone":"3375-c",
        "hex":"#7ae1bf"
    },
    {
        "pantone":"3385-c",
        "hex":"#47d7ac"
    },
    {
        "pantone":"3395-c",
        "hex":"#00c389"
    },
    {
        "pantone":"3405-c",
        "hex":"#00af66"
    },
    {
        "pantone":"3415-c",
        "hex":"#007749"
    },
    {
        "pantone":"3425-c",
        "hex":"#006341"
    },
    {
        "pantone":"3435-c",
        "hex":"#154734"
    },
    {
        "pantone":"344-c",
        "hex":"#a0dab3"
    },
    {
        "pantone":"345-c",
        "hex":"#91d6ac"
    },
    {
        "pantone":"346-c",
        "hex":"#71cc98"
    },
    {
        "pantone":"347-c",
        "hex":"#009a44"
    },
    {
        "pantone":"348-c",
        "hex":"#00843d"
    },
    {
        "pantone":"349-c",
        "hex":"#046a38"
    },
    {
        "pantone":"350-c",
        "hex":"#2c5234"
    },
    {
        "pantone":"351-c",
        "hex":"#a2e4b8"
    },
    {
        "pantone":"352-c",
        "hex":"#8fe2b0"
    },
    {
        "pantone":"353-c",
        "hex":"#80e0a7"
    },
    {
        "pantone":"354-c",
        "hex":"#00b140"
    },
    {
        "pantone":"355-c",
        "hex":"#009639"
    },
    {
        "pantone":"356-c",
        "hex":"#007a33"
    },
    {
        "pantone":"357-c",
        "hex":"#215732"
    },
    {
        "pantone":"7478-c",
        "hex":"#9be3bf"
    },
    {
        "pantone":"7479-c",
        "hex":"#26d07c"
    },
    {
        "pantone":"7480-c",
        "hex":"#00bf6f"
    },
    {
        "pantone":"7481-c",
        "hex":"#00b74f"
    },
    {
        "pantone":"7482-c",
        "hex":"#009f4d"
    },
    {
        "pantone":"7483-c",
        "hex":"#275d38"
    },
    {
        "pantone":"7484-c",
        "hex":"#00573f"
    },
    {
        "pantone":"7730-c",
        "hex":"#4b9560"
    },
    {
        "pantone":"7731-c",
        "hex":"#228848"
    },
    {
        "pantone":"7732-c",
        "hex":"#007a3e"
    },
    {
        "pantone":"7733-c",
        "hex":"#007041"
    },
    {
        "pantone":"7734-c",
        "hex":"#286140"
    },
    {
        "pantone":"7735-c",
        "hex":"#36573b"
    },
    {
        "pantone":"7736-c",
        "hex":"#395542"
    },
    {
        "pantone":"7737-c",
        "hex":"#6ba539"
    },
    {
        "pantone":"7738-c",
        "hex":"#48a23f"
    },
    {
        "pantone":"7739-c",
        "hex":"#319b42"
    },
    {
        "pantone":"7740-c",
        "hex":"#3a913f"
    },
    {
        "pantone":"7741-c",
        "hex":"#44883e"
    },
    {
        "pantone":"7742-c",
        "hex":"#4a773c"
    },
    {
        "pantone":"7743-c",
        "hex":"#44693d"
    },
    {
        "pantone":"358-c",
        "hex":"#addc91"
    },
    {
        "pantone":"359-c",
        "hex":"#a1d884"
    },
    {
        "pantone":"360-c",
        "hex":"#6cc24a"
    },
    {
        "pantone":"361-c",
        "hex":"#43b02a"
    },
    {
        "pantone":"362-c",
        "hex":"#509e2f"
    },
    {
        "pantone":"363-c",
        "hex":"#4c8c2b"
    },
    {
        "pantone":"364-c",
        "hex":"#4a7729"
    },
    {
        "pantone":"7485-c",
        "hex":"#d0debb"
    },
    {
        "pantone":"7486-c",
        "hex":"#bce194"
    },
    {
        "pantone":"7487-c",
        "hex":"#8edd65"
    },
    {
        "pantone":"7488-c",
        "hex":"#78d64b"
    },
    {
        "pantone":"7489-c",
        "hex":"#74aa50"
    },
    {
        "pantone":"7490-c",
        "hex":"#719949"
    },
    {
        "pantone":"7491-c",
        "hex":"#79863c"
    },
    {
        "pantone":"365-c",
        "hex":"#c2e189"
    },
    {
        "pantone":"366-c",
        "hex":"#b7dd79"
    },
    {
        "pantone":"367-c",
        "hex":"#a4d65e"
    },
    {
        "pantone":"368-c",
        "hex":"#78be20"
    },
    {
        "pantone":"369-c",
        "hex":"#64a70b"
    },
    {
        "pantone":"370-c",
        "hex":"#658d1b"
    },
    {
        "pantone":"371-c",
        "hex":"#546223"
    },
    {
        "pantone":"372-c",
        "hex":"#d4eb8e"
    },
    {
        "pantone":"373-c",
        "hex":"#cdea80"
    },
    {
        "pantone":"374-c",
        "hex":"#c5e86c"
    },
    {
        "pantone":"375-c",
        "hex":"#97d700"
    },
    {
        "pantone":"376-c",
        "hex":"#84bd00"
    },
    {
        "pantone":"377-c",
        "hex":"#7a9a01"
    },
    {
        "pantone":"378-c",
        "hex":"#59621d"
    },
    {
        "pantone":"580-c",
        "hex":"#c4d6a4"
    },
    {
        "pantone":"579-c",
        "hex":"#bcd19b"
    },
    {
        "pantone":"578-c",
        "hex":"#b7ce95"
    },
    {
        "pantone":"577-c",
        "hex":"#a9c47f"
    },
    {
        "pantone":"576-c",
        "hex":"#789d4a"
    },
    {
        "pantone":"575-c",
        "hex":"#67823a"
    },
    {
        "pantone":"574-c",
        "hex":"#4e5b31"
    },
    {
        "pantone":"5807-c",
        "hex":"#d0d1ab"
    },
    {
        "pantone":"5797-c",
        "hex":"#c6c89b"
    },
    {
        "pantone":"5787-c",
        "hex":"#babd8b"
    },
    {
        "pantone":"5777-c",
        "hex":"#a2a569"
    },
    {
        "pantone":"5767-c",
        "hex":"#8a8d4a"
    },
    {
        "pantone":"5757-c",
        "hex":"#6d712e"
    },
    {
        "pantone":"5747-c",
        "hex":"#3d441e"
    },
    {
        "pantone":"5875-c",
        "hex":"#d2ce9e"
    },
    {
        "pantone":"5865-c",
        "hex":"#cbc793"
    },
    {
        "pantone":"5855-c",
        "hex":"#c0bb87"
    },
    {
        "pantone":"5845-c",
        "hex":"#afa96e"
    },
    {
        "pantone":"5835-c",
        "hex":"#a09958"
    },
    {
        "pantone":"5825-c",
        "hex":"#89813d"
    },
    {
        "pantone":"5815-c",
        "hex":"#555025"
    },
    {
        "pantone":"5803-c",
        "hex":"#c3c6a8"
    },
    {
        "pantone":"5793-c",
        "hex":"#b3b995"
    },
    {
        "pantone":"5783-c",
        "hex":"#a3aa83"
    },
    {
        "pantone":"5773-c",
        "hex":"#899064"
    },
    {
        "pantone":"5763-c",
        "hex":"#737b4c"
    },
    {
        "pantone":"5753-c",
        "hex":"#5e6738"
    },
    {
        "pantone":"5743-c",
        "hex":"#3e4827"
    },
    {
        "pantone":"7492-c",
        "hex":"#bfcc80"
    },
    {
        "pantone":"7493-c",
        "hex":"#bbc592"
    },
    {
        "pantone":"7494-c",
        "hex":"#9caf88"
    },
    {
        "pantone":"7495-c",
        "hex":"#8f993e"
    },
    {
        "pantone":"7496-c",
        "hex":"#76881d"
    },
    {
        "pantone":"7497-c",
        "hex":"#7a7256"
    },
    {
        "pantone":"7498-c",
        "hex":"#5b6236"
    },
    {
        "pantone":"7744-c",
        "hex":"#babc16"
    },
    {
        "pantone":"7745-c",
        "hex":"#abad23"
    },
    {
        "pantone":"7746-c",
        "hex":"#999b30"
    },
    {
        "pantone":"7747-c",
        "hex":"#888d30"
    },
    {
        "pantone":"7748-c",
        "hex":"#7c8034"
    },
    {
        "pantone":"7749-c",
        "hex":"#727337"
    },
    {
        "pantone":"7750-c",
        "hex":"#656635"
    },
    {
        "pantone":"379-c",
        "hex":"#e2e868"
    },
    {
        "pantone":"380-c",
        "hex":"#dbe442"
    },
    {
        "pantone":"381-c",
        "hex":"#cedc00"
    },
    {
        "pantone":"382-c",
        "hex":"#c4d600"
    },
    {
        "pantone":"383-c",
        "hex":"#a8ad00"
    },
    {
        "pantone":"384-c",
        "hex":"#949300"
    },
    {
        "pantone":"385-c",
        "hex":"#787121"
    },
    {
        "pantone":"386-c",
        "hex":"#e9ec6b"
    },
    {
        "pantone":"387-c",
        "hex":"#e3e935"
    },
    {
        "pantone":"388-c",
        "hex":"#e0e721"
    },
    {
        "pantone":"389-c",
        "hex":"#d0df00"
    },
    {
        "pantone":"390-c",
        "hex":"#b5bd00"
    },
    {
        "pantone":"391-c",
        "hex":"#9a9500"
    },
    {
        "pantone":"392-c",
        "hex":"#827a04"
    },
    {
        "pantone":"587-c",
        "hex":"#e3e48d"
    },
    {
        "pantone":"586-c",
        "hex":"#e0e27c"
    },
    {
        "pantone":"585-c",
        "hex":"#dbde70"
    },
    {
        "pantone":"584-c",
        "hex":"#d2d755"
    },
    {
        "pantone":"583-c",
        "hex":"#b7bf10"
    },
    {
        "pantone":"582-c",
        "hex":"#8e8c13"
    },
    {
        "pantone":"581-c",
        "hex":"#625d20"
    },
    {
        "pantone":"393-c",
        "hex":"#f0ec74"
    },
    {
        "pantone":"394-c",
        "hex":"#ede939"
    },
    {
        "pantone":"395-c",
        "hex":"#ece81a"
    },
    {
        "pantone":"396-c",
        "hex":"#e1e000"
    },
    {
        "pantone":"397-c",
        "hex":"#bfb800"
    },
    {
        "pantone":"398-c",
        "hex":"#ada400"
    },
    {
        "pantone":"399-c",
        "hex":"#a09200"
    },
    {
        "pantone":"3935-c",
        "hex":"#f3ea5d"
    },
    {
        "pantone":"3945-c",
        "hex":"#f3e500"
    },
    {
        "pantone":"3955-c",
        "hex":"#efdf00"
    },
    {
        "pantone":"3965-c",
        "hex":"#eedc00"
    },
    {
        "pantone":"3975-c",
        "hex":"#bba600"
    },
    {
        "pantone":"3985-c",
        "hex":"#9a8700"
    },
    {
        "pantone":"3995-c",
        "hex":"#685c20"
    },
    {
        "pantone":"600-c",
        "hex":"#f1eb9c"
    },
    {
        "pantone":"601-c",
        "hex":"#f0e991"
    },
    {
        "pantone":"602-c",
        "hex":"#f0e87b"
    },
    {
        "pantone":"603-c",
        "hex":"#ede04b"
    },
    {
        "pantone":"604-c",
        "hex":"#eada24"
    },
    {
        "pantone":"605-c",
        "hex":"#e1cd00"
    },
    {
        "pantone":"606-c",
        "hex":"#cfb500"
    },
    {
        "pantone":"607-c",
        "hex":"#ebe49a"
    },
    {
        "pantone":"608-c",
        "hex":"#e9e186"
    },
    {
        "pantone":"609-c",
        "hex":"#e6de77"
    },
    {
        "pantone":"610-c",
        "hex":"#e1d555"
    },
    {
        "pantone":"611-c",
        "hex":"#d7c826"
    },
    {
        "pantone":"612-c",
        "hex":"#c4b000"
    },
    {
        "pantone":"613-c",
        "hex":"#b39b00"
    },
    {
        "pantone":"461-c",
        "hex":"#e9df97"
    },
    {
        "pantone":"460-c",
        "hex":"#e4d77e"
    },
    {
        "pantone":"459-c",
        "hex":"#decd63"
    },
    {
        "pantone":"458-c",
        "hex":"#d9c756"
    },
    {
        "pantone":"457-c",
        "hex":"#b89d18"
    },
    {
        "pantone":"456-c",
        "hex":"#a28e2a"
    },
    {
        "pantone":"455-c",
        "hex":"#695b24"
    },
    {
        "pantone":"614-c",
        "hex":"#dcd59a"
    },
    {
        "pantone":"615-c",
        "hex":"#d6cf8d"
    },
    {
        "pantone":"616-c",
        "hex":"#d0c883"
    },
    {
        "pantone":"617-c",
        "hex":"#c0b561"
    },
    {
        "pantone":"618-c",
        "hex":"#ac9f3c"
    },
    {
        "pantone":"619-c",
        "hex":"#9f912a"
    },
    {
        "pantone":"620-c",
        "hex":"#8a7b19"
    },
    {
        "pantone":"7751-c",
        "hex":"#cab64b"
    },
    {
        "pantone":"7752-c",
        "hex":"#cfb023"
    },
    {
        "pantone":"7753-c",
        "hex":"#c1a01e"
    },
    {
        "pantone":"7754-c",
        "hex":"#a08629"
    },
    {
        "pantone":"7755-c",
        "hex":"#897630"
    },
    {
        "pantone":"7756-c",
        "hex":"#736635"
    },
    {
        "pantone":"7757-c",
        "hex":"#675e33"
    },
    {
        "pantone":"7758-c",
        "hex":"#d4c304"
    },
    {
        "pantone":"7759-c",
        "hex":"#c4b200"
    },
    {
        "pantone":"7760-c",
        "hex":"#91852c"
    },
    {
        "pantone":"7761-c",
        "hex":"#747136"
    },
    {
        "pantone":"7762-c",
        "hex":"#5d6439"
    },
    {
        "pantone":"7763-c",
        "hex":"#585c3b"
    },
    {
        "pantone":"7764-c",
        "hex":"#535435"
    },
    {
        "pantone":"7765-c",
        "hex":"#bbb323"
    },
    {
        "pantone":"7766-c",
        "hex":"#b4a91f"
    },
    {
        "pantone":"7767-c",
        "hex":"#aa9d2e"
    },
    {
        "pantone":"7768-c",
        "hex":"#8f7e35"
    },
    {
        "pantone":"7769-c",
        "hex":"#716135"
    },
    {
        "pantone":"7770-c",
        "hex":"#635939"
    },
    {
        "pantone":"7771-c",
        "hex":"#4e4934"
    },
    {
        "pantone":"4545-c",
        "hex":"#d5cb9f"
    },
    {
        "pantone":"4535-c",
        "hex":"#cfc493"
    },
    {
        "pantone":"4525-c",
        "hex":"#c5b783"
    },
    {
        "pantone":"4515-c",
        "hex":"#b3a369"
    },
    {
        "pantone":"4505-c",
        "hex":"#998542"
    },
    {
        "pantone":"4495-c",
        "hex":"#8c7732"
    },
    {
        "pantone":"4485-c",
        "hex":"#614f25"
    },
    {
        "pantone":"454-c",
        "hex":"#cac7a7"
    },
    {
        "pantone":"453-c",
        "hex":"#bfbb98"
    },
    {
        "pantone":"452-c",
        "hex":"#b0aa7e"
    },
    {
        "pantone":"451-c",
        "hex":"#9b945f"
    },
    {
        "pantone":"450-c",
        "hex":"#594a25"
    },
    {
        "pantone":"449-c",
        "hex":"#524727"
    },
    {
        "pantone":"448-c",
        "hex":"#4a412a"
    },
    {
        "pantone":"7499-c",
        "hex":"#f1e6b2"
    },
    {
        "pantone":"7500-c",
        "hex":"#dfd1a7"
    },
    {
        "pantone":"7501-c",
        "hex":"#d9c89e"
    },
    {
        "pantone":"7502-c",
        "hex":"#ceb888"
    },
    {
        "pantone":"7503-c",
        "hex":"#a89968"
    },
    {
        "pantone":"7504-c",
        "hex":"#94795d"
    },
    {
        "pantone":"7505-c",
        "hex":"#816040"
    },
    {
        "pantone":"468-c",
        "hex":"#ddcba4"
    },
    {
        "pantone":"467-c",
        "hex":"#d3bc8d"
    },
    {
        "pantone":"466-c",
        "hex":"#c6aa76"
    },
    {
        "pantone":"465-c",
        "hex":"#b9975b"
    },
    {
        "pantone":"464-c",
        "hex":"#8b5b29"
    },
    {
        "pantone":"463-c",
        "hex":"#744f28"
    },
    {
        "pantone":"462-c",
        "hex":"#5c462b"
    },
    {
        "pantone":"7506-c",
        "hex":"#efdbb2"
    },
    {
        "pantone":"7507-c",
        "hex":"#fcd299"
    },
    {
        "pantone":"7508-c",
        "hex":"#e1b87f"
    },
    {
        "pantone":"7509-c",
        "hex":"#d6a461"
    },
    {
        "pantone":"7510-c",
        "hex":"#c6893f"
    },
    {
        "pantone":"7511-c",
        "hex":"#b77729"
    },
    {
        "pantone":"7512-c",
        "hex":"#a6631b"
    },
    {
        "pantone":"719-c",
        "hex":"#edc8a3"
    },
    {
        "pantone":"720-c",
        "hex":"#e7b78a"
    },
    {
        "pantone":"721-c",
        "hex":"#dda46f"
    },
    {
        "pantone":"722-c",
        "hex":"#c88242"
    },
    {
        "pantone":"723-c",
        "hex":"#b36924"
    },
    {
        "pantone":"724-c",
        "hex":"#934d11"
    },
    {
        "pantone":"725-c",
        "hex":"#7d3f16"
    },
    {
        "pantone":"475-c",
        "hex":"#f3cfb3"
    },
    {
        "pantone":"474-c",
        "hex":"#f1c6a7"
    },
    {
        "pantone":"473-c",
        "hex":"#f0bf9b"
    },
    {
        "pantone":"472-c",
        "hex":"#e59e6d"
    },
    {
        "pantone":"471-c",
        "hex":"#b86125"
    },
    {
        "pantone":"470-c",
        "hex":"#a45a2a"
    },
    {
        "pantone":"469-c",
        "hex":"#693f23"
    },
    {
        "pantone":"726-c",
        "hex":"#e0c09f"
    },
    {
        "pantone":"727-c",
        "hex":"#d9b48f"
    },
    {
        "pantone":"728-c",
        "hex":"#cda077"
    },
    {
        "pantone":"729-c",
        "hex":"#b58150"
    },
    {
        "pantone":"730-c",
        "hex":"#9e652e"
    },
    {
        "pantone":"731-c",
        "hex":"#774212"
    },
    {
        "pantone":"732-c",
        "hex":"#623412"
    },
    {
        "pantone":"4685-c",
        "hex":"#e0c6ad"
    },
    {
        "pantone":"4675-c",
        "hex":"#dcbfa6"
    },
    {
        "pantone":"4665-c",
        "hex":"#cda788"
    },
    {
        "pantone":"4655-c",
        "hex":"#bf9474"
    },
    {
        "pantone":"4645-c",
        "hex":"#ad7c59"
    },
    {
        "pantone":"4635-c",
        "hex":"#946037"
    },
    {
        "pantone":"4625-c",
        "hex":"#4f2c1d"
    },
    {
        "pantone":"7513-c",
        "hex":"#e1b7a7"
    },
    {
        "pantone":"7514-c",
        "hex":"#d5a286"
    },
    {
        "pantone":"7515-c",
        "hex":"#c58b68"
    },
    {
        "pantone":"7516-c",
        "hex":"#99552b"
    },
    {
        "pantone":"7517-c",
        "hex":"#85431e"
    },
    {
        "pantone":"7518-c",
        "hex":"#6d4f47"
    },
    {
        "pantone":"7519-c",
        "hex":"#5e4b3c"
    },
    {
        "pantone":"4755-c",
        "hex":"#d7c4b7"
    },
    {
        "pantone":"4745-c",
        "hex":"#cdb5a7"
    },
    {
        "pantone":"4735-c",
        "hex":"#c0a392"
    },
    {
        "pantone":"4725-c",
        "hex":"#ae8a79"
    },
    {
        "pantone":"4715-c",
        "hex":"#956c58"
    },
    {
        "pantone":"4705-c",
        "hex":"#7c4d3a"
    },
    {
        "pantone":"4695-c",
        "hex":"#5b3427"
    },
    {
        "pantone":"482-c",
        "hex":"#dbc8b6"
    },
    {
        "pantone":"481-c",
        "hex":"#d3bba8"
    },
    {
        "pantone":"480-c",
        "hex":"#c6a992"
    },
    {
        "pantone":"479-c",
        "hex":"#aa8066"
    },
    {
        "pantone":"478-c",
        "hex":"#703f2a"
    },
    {
        "pantone":"477-c",
        "hex":"#623b2a"
    },
    {
        "pantone":"476-c",
        "hex":"#4e3629"
    },
    {
        "pantone":"7527-c",
        "hex":"#d6d2c4"
    },
    {
        "pantone":"7528-c",
        "hex":"#c5b9ac"
    },
    {
        "pantone":"7529-c",
        "hex":"#b7a99a"
    },
    {
        "pantone":"7530-c",
        "hex":"#a39382"
    },
    {
        "pantone":"7531-c",
        "hex":"#7a6855"
    },
    {
        "pantone":"7532-c",
        "hex":"#63513d"
    },
    {
        "pantone":"7533-c",
        "hex":"#473729"
    },
    {
        "pantone":"7534-c",
        "hex":"#d1ccbd"
    },
    {
        "pantone":"7535-c",
        "hex":"#b7b09c"
    },
    {
        "pantone":"7536-c",
        "hex":"#a69f88"
    },
    {
        "pantone":"7537-c",
        "hex":"#a7aca2"
    },
    {
        "pantone":"7538-c",
        "hex":"#949a90"
    },
    {
        "pantone":"7539-c",
        "hex":"#8e9089"
    },
    {
        "pantone":"7540-c",
        "hex":"#4b4f54"
    },
    {
        "pantone":"427-c",
        "hex":"#d0d3d4"
    },
    {
        "pantone":"428-c",
        "hex":"#c1c6c8"
    },
    {
        "pantone":"429-c",
        "hex":"#a2aaad"
    },
    {
        "pantone":"430-c",
        "hex":"#7c878e"
    },
    {
        "pantone":"431-c",
        "hex":"#5b6770"
    },
    {
        "pantone":"432-c",
        "hex":"#333f48"
    },
    {
        "pantone":"433-c",
        "hex":"#1d252d"
    },
    {
        "pantone":"420-c",
        "hex":"#c7c9c7"
    },
    {
        "pantone":"421-c",
        "hex":"#b2b4b2"
    },
    {
        "pantone":"422-c",
        "hex":"#9ea2a2"
    },
    {
        "pantone":"423-c",
        "hex":"#898d8d"
    },
    {
        "pantone":"424-c",
        "hex":"#707372"
    },
    {
        "pantone":"425-c",
        "hex":"#54585a"
    },
    {
        "pantone":"426-c",
        "hex":"#25282a"
    },
    {
        "pantone":"441-c",
        "hex":"#bec6c4"
    },
    {
        "pantone":"442-c",
        "hex":"#a2acab"
    },
    {
        "pantone":"443-c",
        "hex":"#919d9d"
    },
    {
        "pantone":"444-c",
        "hex":"#717c7d"
    },
    {
        "pantone":"445-c",
        "hex":"#505759"
    },
    {
        "pantone":"446-c",
        "hex":"#3f4444"
    },
    {
        "pantone":"447-c",
        "hex":"#373a36"
    },
    {
        "pantone":"413-c",
        "hex":"#babbb1"
    },
    {
        "pantone":"414-c",
        "hex":"#a8a99e"
    },
    {
        "pantone":"415-c",
        "hex":"#919388"
    },
    {
        "pantone":"416-c",
        "hex":"#7e7f74"
    },
    {
        "pantone":"417-c",
        "hex":"#65665c"
    },
    {
        "pantone":"418-c",
        "hex":"#51534a"
    },
    {
        "pantone":"419-c",
        "hex":"#212322"
    },
    {
        "pantone":"400-c",
        "hex":"#c4bfb6"
    },
    {
        "pantone":"401-c",
        "hex":"#afa9a0"
    },
    {
        "pantone":"402-c",
        "hex":"#9d968d"
    },
    {
        "pantone":"403-c",
        "hex":"#8c857b"
    },
    {
        "pantone":"404-c",
        "hex":"#776e64"
    },
    {
        "pantone":"405-c",
        "hex":"#696158"
    },
    {
        "pantone":"406-c",
        "hex":"#c4bcb7"
    },
    {
        "pantone":"407-c",
        "hex":"#b2a8a2"
    },
    {
        "pantone":"408-c",
        "hex":"#978c87"
    },
    {
        "pantone":"409-c",
        "hex":"#857874"
    },
    {
        "pantone":"410-c",
        "hex":"#746661"
    },
    {
        "pantone":"411-c",
        "hex":"#5e514d"
    },
    {
        "pantone":"412-c",
        "hex":"#382f2d"
    },
    {
        "pantone":"434-c",
        "hex":"#d0c4c5"
    },
    {
        "pantone":"435-c",
        "hex":"#c1b2b6"
    },
    {
        "pantone":"436-c",
        "hex":"#ab989d"
    },
    {
        "pantone":"437-c",
        "hex":"#7b6469"
    },
    {
        "pantone":"438-c",
        "hex":"#584446"
    },
    {
        "pantone":"439-c",
        "hex":"#453536"
    },
    {
        "pantone":"440-c",
        "hex":"#382e2c"
    },
    {
        "pantone":"warm-gray-1-c",
        "hex":"#d7d2cb"
    },
    {
        "pantone":"warm-gray-2-c",
        "hex":"#cbc4bc"
    },
    {
        "pantone":"warm-gray-3-c",
        "hex":"#bfb8af"
    },
    {
        "pantone":"warm-gray-4-c",
        "hex":"#b6ada5"
    },
    {
        "pantone":"warm-gray-5-c",
        "hex":"#aca39a"
    },
    {
        "pantone":"warm-gray-6-c",
        "hex":"#a59c94"
    },
    {
        "pantone":"warm-gray-7-c",
        "hex":"#968c83"
    },
    {
        "pantone":"warm-gray-8-c",
        "hex":"#8c8279"
    },
    {
        "pantone":"warm-gray-9-c",
        "hex":"#83786f"
    },
    {
        "pantone":"warm-gray-10-c",
        "hex":"#796e65"
    },
    {
        "pantone":"warm-gray-11-c",
        "hex":"#6e6259"
    },
    {
        "pantone":"cool-gray-1-c",
        "hex":"#d9d9d6"
    },
    {
        "pantone":"cool-gray-2-c",
        "hex":"#d0d0ce"
    },
    {
        "pantone":"cool-gray-3-c",
        "hex":"#c8c9c7"
    },
    {
        "pantone":"cool-gray-4-c",
        "hex":"#bbbcbc"
    },
    {
        "pantone":"cool-gray-5-c",
        "hex":"#b1b3b3"
    },
    {
        "pantone":"cool-gray-6-c",
        "hex":"#a7a8aa"
    },
    {
        "pantone":"cool-gray-7-c",
        "hex":"#97999b"
    },
    {
        "pantone":"cool-gray-8-c",
        "hex":"#888b8d"
    },
    {
        "pantone":"cool-gray-9-c",
        "hex":"#75787b"
    },
    {
        "pantone":"cool-gray-10-c",
        "hex":"#63666a"
    },
    {
        "pantone":"cool-gray-11-c",
        "hex":"#53565a"
    },
    {
        "pantone":"black-2-c",
        "hex":"#332f21"
    },
    {
        "pantone":"black-3-c",
        "hex":"#212721"
    },
    {
        "pantone":"black-4-c",
        "hex":"#31261d"
    },
    {
        "pantone":"black-5-c",
        "hex":"#3e2b2e"
    },
    {
        "pantone":"black-6-c",
        "hex":"#101820"
    },
    {
        "pantone":"black-7-c",
        "hex":"#3d3935"
    },
    {
        "pantone":"yellow-c",
        "hex":"#fedd00"
    },
    {
        "pantone":"yellow-012-c",
        "hex":"#ffd700"
    },
    {
        "pantone":"orange-021-c",
        "hex":"#fe5000"
    },
    {
        "pantone":"warm-red-c",
        "hex":"#f9423a"
    },
    {
        "pantone":"red-032-c",
        "hex":"#ef3340"
    },
    {
        "pantone":"rubine-red-c",
        "hex":"#ce0058"
    },
    {
        "pantone":"rhodamine-red-c",
        "hex":"#e10098"
    },
    {
        "pantone":"purple-c",
        "hex":"#bb29bb"
    },
    {
        "pantone":"violet-c",
        "hex":"#440099"
    },
    {
        "pantone":"blue-072-c",
        "hex":"#10069f"
    },
    {
        "pantone":"reflex-blue-c",
        "hex":"#001489"
    },
    {
        "pantone":"process-blue-c",
        "hex":"#0085ca"
    },
    {
        "pantone":"green-c",
        "hex":"#00ab84"
    },
    {
        "pantone":"black-c",
        "hex":"#2d2926"
    },
    {
        "pantone":"yellow-0131-c",
        "hex":"#f2f0a1"
    },
    {
        "pantone":"red-0331-c",
        "hex":"#fcaebb"
    },
    {
        "pantone":"magenta-0521-c",
        "hex":"#f1b2dc"
    },
    {
        "pantone":"violet-0631-c",
        "hex":"#bf9bde"
    },
    {
        "pantone":"blue-0821-c",
        "hex":"#74d1ea"
    },
    {
        "pantone":"green-0921-c",
        "hex":"#9de7d7"
    },
    {
        "pantone":"black-0961-c",
        "hex":"#9e978e"
    },
    {
        "pantone":"801-c",
        "hex":"#009ace"
    },
    {
        "pantone":"802-c",
        "hex":"#44d62c"
    },
    {
        "pantone":"803-c",
        "hex":"#ffe900"
    },
    {
        "pantone":"804-c",
        "hex":"#ffaa4d"
    },
    {
        "pantone":"805-c",
        "hex":"#ff7276"
    },
    {
        "pantone":"806-c",
        "hex":"#ff3eb5"
    },
    {
        "pantone":"807-c",
        "hex":"#ea27c2"
    },
    {
        "pantone":"871-c",
        "hex":"#84754e"
    },
    {
        "pantone":"872-c",
        "hex":"#85714d"
    },
    {
        "pantone":"873-c",
        "hex":"#866d4b"
    },
    {
        "pantone":"874-c",
        "hex":"#8b6f4e"
    },
    {
        "pantone":"875-c",
        "hex":"#87674f"
    },
    {
        "pantone":"876-c",
        "hex":"#8b634b"
    },
    {
        "pantone":"877-c",
        "hex":"#8a8d8f"
    },
    {
        "pantone":"medium-yellow-c",
        "hex":"#ffd900"
    },
    {
        "pantone":"bright-orange-c",
        "hex":"#ff5e00"
    },
    {
        "pantone":"bright-red-c",
        "hex":"#f93822"
    },
    {
        "pantone":"strong-red-c",
        "hex":"#ce0056"
    },
    {
        "pantone":"pink-c",
        "hex":"#d62598"
    },
    {
        "pantone":"medium-purple-c",
        "hex":"#4e008e"
    },
    {
        "pantone":"dark-blue-c",
        "hex":"#00239c"
    },
    {
        "pantone":"medium-blue-c",
        "hex":"#0084ca"
    },
    {
        "pantone":"bright-green-c",
        "hex":"#00b08b"
    },
    {
        "pantone":"neutral-black-c",
        "hex":"#222223"
    }
]