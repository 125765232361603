<template>
    <div class="popup-menu">
        <div class="popup-menu-dot" :class="{'is-sliding': sliding}" :style="dotStyle"><div></div></div>
        <a
            href="#" class="popup-menu-link"
            v-for="(label, slug) in links"
            :key="slug"
            @click.prevent="$emit('input', slug)"
            :class="{'is-active': slug === value}"
        >
            {{ translate ? $t(label) : label }}
        </a>
    </div>
</template>

<script>
    export default {
        name: 'PopupMenu',
        props: {
            links: Object,
            value: String,
            translate: {
                type: Boolean,
                default: true
            }
        },
        data: () => ({
            sliding: false
        }),
        computed: {
            dotStyle() {
                let index = Object.keys(this.links).indexOf(this.value)
                if(index < 0) {
                    index = 0
                }
                const top = index * 32
                return {
                    transform: 'translate(0, ' + top + 'px)'
                }
            }
        },
        watch: {
            value() {
                this.sliding = true
                this.slidingTimeout && clearTimeout(this.slidingTimeout)
                this.slidingTimeout = setTimeout(() => { this.sliding = false }, 250)
            }
        }
    }
</script>

