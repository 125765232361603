<template>
    <div>
        <div class="popup-header is-small">
            <div class="popup-header-icon">
                <svg-sprite icon="information" width="30" height="30" />
            </div>
            <div class="popup-logo">
                <Logo />
            </div>
        </div>
        <div class="popup-content">
            <div class="popup-main">
                <p class="popup-text">
                    En version <b>light</b>, le configurateur vous donne un aperçu recadré de votre article.<br>
                    Le fonctionnement reste le même et vous pouvez bien entendu passer commande :)
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import Logo from '../../ui/Logo'
    export default {
        name: 'PopupLightinfo',
        components: {Logo}
    }
</script>
