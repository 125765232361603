
const state = {
    error: false,
    message: '',
    showed: false
}

const getters = {

}

const actions = {

}

const mutations = {
    close(state) {
        state.showed = false
    },
    open(state, {error = false, message}) {
        state.error = error
        state.message = message
        state.showed = true
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
