import Vue from 'vue'

Vue.filter('price', function(price, showCurrency = true) {
    let txt = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
    }).format(price)
    if(!showCurrency) {
        txt = txt.replace(' €', '')
    }
    return txt
})