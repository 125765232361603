<script>
export default {
    name: "ScreenshotItemImage",
    props: {
        image: Object,
        variant: Object
    },
    computed: {
        styles() {
            const wPercent = this.image.width / this.variant.width
            const hPercent = this.image.height / this.variant.height
            const leftPercent = (this.image.x / this.variant.width) - (wPercent / 2)
            const topPercent = (this.image.y / this.variant.height) - (hPercent / 2)
            return {
                top: (topPercent * 100) + '%',
                left: (leftPercent * 100) + '%',
                width: (wPercent * 100) + '%',
                height: (hPercent  * 100) + '%',
                transform: 'rotate('+this.image.rotation+'deg)'
            }
        }
    }
}
</script>

<template>
    <img :src="image.imageObj.url" alt="" class="screenshot-item is-image" v-if="!image.deleted" :style="styles">
</template>
