<template>
    <div class="sidebar-section-content">
        <button class="buttonclose cartitem-delete" v-if="close" @click="$store.commit('setSidebarToggle', '')" title="Fermer">
        </button>
        <div class="inner">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import Velocity from 'velocity-animate'

    export default {
        name: 'SidebarToggle',
        props: {
            slug: String,
            close: {
                type: Boolean,
                default: true
            }
        },
        mounted() {
            if(!this.isOpened) {
                this.$el.style.display = 'none'
            }
        },
        computed: {
            ...mapState({
                 openedSlug: 'sidebarToggleOpened'
            }),
            isOpened() {
                return this.openedSlug === this.slug
            }
        },
        watch: {
            isOpened() {
                Velocity(
                    this.$el,
                    this.isOpened ? 'slideDown' : 'slideUp',
                    {
                        duration: 500,
                        easing: 'easeOutCubic'
                    }
                )
            }
        }
    }
</script>
