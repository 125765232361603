import Vue from 'vue'

const state = {
    items: {},
    selected: '',
}

const getters = {
    selected: state => {
        return state.selected
    },
    selectedItem: state => {
        return state.items[state.selected] ? state.items[state.selected] : false
    },
    techniquesList: state => {
        let techniques = {}
        for (const m of Object.values(state.items)) {
            for (const t of m.types) {

                for(const v of t.variants) {
                    techniques[v.technicalLabel] = {
                        description: v.description,
                        techImage: v.techImage
                    }
                }
            }
        }
        const techniquesArray = []
        for(const key of Object.keys(techniques)) {
            techniquesArray.push({
                label: key,
                description: techniques[key].description,
                techImage: techniques[key].techImage
            })
        }
        return techniquesArray
    },
    variantEnabled: state => (variantId, subvariantId = null) => {
        let enabled = true
        let marquageDisabled = null
        for(const marquage of Object.values(state.items)) {
            if(marquage.types && marquage.config.markingTypeVariantId) {
                for(const marquageType of marquage.types) {
                    if(marquageType.variants) {
                        for(const marquageVariant of marquageType.variants) {
                            // variant selected ?
                            if(marquageVariant.id == marquage.config.markingTypeVariantId) {
                                if(marquageVariant.configurationVariants) {
                                    let variantPresentInConfig = false
                                    if(Object.keys(marquageVariant.configurationVariants).includes('' + variantId)) {
                                        variantPresentInConfig = true
                                        if(subvariantId) {
                                            for(const subvariants of Object.values(marquageVariant.configurationVariants)) {
                                                if(subvariants && !subvariants.includes(parseInt(subvariantId))) {
                                                    variantPresentInConfig = false
                                                }
                                            }
                                        }
                                    }
                                    if(!variantPresentInConfig) {
                                        marquageDisabled = marquageType.label + ' ' + marquageVariant.label
                                    }
                                    enabled = variantPresentInConfig
                                }
                            }
                        }
                    }
                }
            }
        }
        return {enabled, marquage: marquageDisabled}
    },
    /**
     * get selected technique variant for a marking, if none selected, return first one
     * @param state
     * @returns {function(*): null}
     */
    currentTechnique: state => (markingId) => {
        const marking = state.items[markingId]
        let technique = null
        let first = null
        if (marking) {
            for (const t of marking.types) {
                for (const v of t.variants) {
                    if (!first) {
                        first = v
                    }
                    if (v.id === marking.config.markingTypeVariantId) {
                        technique = v
                    }
                }
            }
        }
        if (!technique) {
            technique = first
        }
        if(technique && !(technique.width || technique.height)) {
            // no dimensions, create them from centimeter sizes
            const heightCm = technique.heightCm || technique.diameter
            const widthCm = technique.widthCm || technique.diameter
            const height = heightCm * 50
            const width = widthCm * 50
            technique = {
                ...technique,
                width: width,
                height: height,
                startX: width / 10,
                startY: height / 10,
                endX: width / 10 + width,
                endY: height / 10 + height,
                maxX: width * 1.2,
                maxY: height * 1.2
            }
        }
        return technique
    },
    qtyMinSelectedMarkings: state => {
        let qtyMin = 0
        for(const marquage of Object.values(state.items)) {
            for(const technique of Object.values(marquage.types)) {
                for(const variant of technique.variants) {
                    if(marquage.config.markingTypeVariantId === variant.id) {
                        // la technique de marquage est selectionnée, check la quantite minimale commandable
                        if(variant.priceGrid && variant.priceGrid.length) {
                            const qtyMinGrid = parseInt(variant.priceGrid[0].floor)
                            if(qtyMinGrid > qtyMin) {
                                qtyMin = qtyMinGrid
                            }
                        }
                    }
                }
            }
        }
        return qtyMin
    }
}

const actions = {
    selectItem({commit, rootGetters, getters}, id) {
        const old = getters.selectedItem
        let currentVariant = null
        let currentTechnique = null
        if(old) {
            currentTechnique = old.config.markingTypeLabel
            currentVariant = old.config.markingTypeVariantLabel
            // if currently selected item has no custom elements, unselect its technique
            const marquageItems = rootGetters['editor/itemsByMarquage']({
                marquage: old.id
            })
            if(Object.keys(marquageItems).length === 0) {
                commit('selectTechnique', {
                    markingId: old.id,
                    techniqueIndex: null,
                    variantId: null
                })
            }
        }

        commit('select', id)

        const marking = getters.selectedItem
        if(marking && !marking.config.markingTypeVariantId) {
            // select a technique in new selected marking, check if there is same technique as currently selected or take first one
            let firstVariant = null
            let foundTechnique = null
            let foundVariant = null
            let defaultTechnique = null
            let defaultVariant = null
            for (const [ti, t] of marking.types.entries()) {
                if(t.label === currentTechnique) {
                    foundTechnique = ti
                }

                for (const v of t.variants) {
                    if (!firstVariant) {
                        firstVariant = v.id
                    }
                    if(v.isDefault) {
                        defaultTechnique = ti
                        defaultVariant = v.id
                    }
                    if (v.label === currentVariant && t.label === currentTechnique) {
                        foundVariant = v.id
                    }
                }
            }
            commit('selectTechnique', {
                markingId: marking.id,
                techniqueIndex: foundTechnique !== null ? foundTechnique : defaultTechnique !== null ? defaultTechnique : 0,
                variantId: foundVariant ? foundVariant : defaultVariant ? defaultVariant : firstVariant ? firstVariant : null
            })
        }
    }
}

const mutations = {
    /**
     * init marquages from product info
     * @param state
     * @param items
     * @param selected
     */
    init(state, {items, selected}) {
        state.items = {
            ...state.items,
            ...items,
        }
        state.selected = selected
    },
    select(state, slug) {
        state.selected = slug
    },
    /**
     * select a technique variant for a marking
     * @param state
     * @param markingId
     * @param techniqueIndex
     * @param variantId
     */
    selectTechnique(state, {markingId, techniqueIndex, variantId}) {
        const marking = state.items[markingId]
        if (marking) {
            if (techniqueIndex === null) {
                Vue.set(state.items[markingId].config, 'markingTypeVariantId', null)
                Vue.set(state.items[markingId].config, 'markingTypeVariantLabel', '')
                Vue.set(state.items[markingId].config, 'markingTypeLabel', '')
            } else {
                const technique = marking.types[techniqueIndex]
                if (technique) {
                    let variant
                    // no variantId, take first one
                    if (!variantId) {
                        variant = technique.variants[0]
                    } else {
                        variant = technique.variants.find(v => v.id === variantId)
                    }

                    if (variant) {
                        Vue.set(state.items[markingId].config, 'markingTypeVariantId', variant.id)
                        Vue.set(state.items[markingId].config, 'markingTypeVariantLabel', variant.label)
                        Vue.set(state.items[markingId].config, 'markingTypeLabel', technique.label)
                    }
                }
            }
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
