<script>
import VariantSpot from "../variant/VariantSpot.vue";

export default {
    components: {VariantSpot},
    props: {
        option: Object,
        isEnabled: Object,
        qtyError: String
    },
    computed: {
        itemPrice() {
            const price = this.$store.getters['cart/priceForVariant'](this.option.id)
            if(price) {
                return price.total
            } else {
                return 0
            }
        },
        itemLabel() {
            if(this.$store.getters['product/light']) {
                return this.$store.state.product.name
            } else {
                return this.option.label
            }
        }
    }
}
</script>

<template>
    <div class="cartitem">
        <div class="cartitem-body">
            <div class="cartitem-thumb">
                <VariantSpot :option="option" :small="true" />
            </div>
            <div class="cartitem-name">
                <b>{{ itemLabel }}</b> - {{ option.config.quantity }} unités
            </div>
            <div class="cartitem-price">
                {{ itemPrice | price }} HT
            </div>
            <button class="cartitem-delete" title="Supprimer" @click.prevent="$emit('delete')"></button>
        </div>
        <div class="cartitem-message is-warning" v-if="!isEnabled.enabled">
            <svg-sprite icon="info-rouge" width="14" height="14" />
            <span>Indisponible en {{ isEnabled.marquage }}</span>
        </div>
        <div class="cartitem-message is-warning" v-if="qtyError">
            <svg-sprite icon="info-rouge" width="14" height="14" />
            <span>{{ qtyError }}</span>
        </div>
    </div>
</template>
