<template>
    <div class="loader" :class="{'is-absolute': absolute}">
        <div></div><div></div><div></div><div></div>
    </div>
</template>

<script>
    export default {
        name: 'Loader',
        props: {
            absolute: Boolean
        }
    }
</script>
