<script>
/*
Cet élément est une copie de l'éditeur de marquage mais sans les éléments d'interface
pour pouvoir générer une image en js pour l'aperçu de la personnalisation
 */

import Screenshot from "@/components/screenshot/Screenshot.vue";

export default {
    name: "Screenshots",
    components: {Screenshot},
    computed: {
        marquagesActifs() {
            const marquages = this.$store.state.marquage.items
            const marquagesActifs = []
            for(const marquage of Object.values(marquages)) {
                if(marquage.types && marquage.config.markingTypeVariantId) {
                    for (const typeIndex in marquage.types) {
                        for(const variantIndex in marquage.types[typeIndex].variants) {
                            const variant = marquage.types[typeIndex].variants[variantIndex]
                            if(variant.id == marquage.config.markingTypeVariantId) {
                                marquagesActifs.push({
                                    marquage: marquage,
                                    variant: variant
                                })
                            }
                        }
                    }
                }
            }
            return marquagesActifs
        }
    }
}
</script>

<template>
    <div class="screenshots">
        <Screenshot v-for="marquage in marquagesActifs" :marquage="marquage" />
    </div>
</template>
