
import { drawText } from 'canvas-txt'

const canvas = document.createElement('canvas')
canvas.width = 1600
canvas.height = 1600
const ctx = canvas.getContext('2d')

const TextCanvas = {
    /**
     * draw text to canvas with correct dimensions to avoid empty paddings
     * @param drawCanvas
     * @param drawContext
     * @param text
     * @param fontSize
     * @param family
     * @param color
     * @param bold
     * @param italic
     * @returns {{width: number, x: number, y: number, height: number}}
     */
    draw(drawCanvas, drawContext, text, fontSize, family, align, lineHeight, color, bold = false, italic = false) {
        const padding = 50
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.textBaseline = 'top'
        ctx.fillStyle = color

        drawText(ctx, text, {
            x: padding,
            y: padding,
            width: canvas.width - padding * 2,
            height: canvas.height - padding * 2,
            fontSize: fontSize,
            font: family,
            fontStyle: italic ? 'italic' : '',
            fontWeight: bold ? '700' : '',
            lineHeight: lineHeight * fontSize,
            align: align
        })

        // Get the pixel data from the canvas
        var data = ctx.getImageData(0, 0, canvas.width, canvas.height).data,
            lineTop = false,
            lineBottom = false,
            columnLeft = false,
            columnRight = false,
            r = canvas.height,
            c = 0

        // Find the last line with a non-white pixel
        while (!lineBottom && r) {
            r--
            for (c = 0; c < canvas.width; c++) {
                if (data[r * canvas.width * 4 + c * 4 + 3]) {
                    lineBottom = r
                    break
                }
            }
        }

        // Find the first line with a non-white pixel
        while (r) {
            r--
            for (c = 0; c < canvas.width; c++) {
                if (data[r * canvas.width * 4 + c * 4 + 3]) {
                    lineTop = r
                    break
                }
            }
        }

        // find the last column with a non-white pixel
        c = canvas.width
        while (!columnRight && c) {
            c--
            for (r = 0; r < canvas.height; r++) {
                if (data[r * canvas.width * 4 + c * 4 + 3]) {
                    columnRight = c
                    break
                }
            }
        }
        // Find the first column with a non-white pixel
        while (c) {
            c--
            for (r = 0; r < canvas.height; r++) {
                if (data[r * canvas.width * 4 + c * 4 + 3]) {
                    columnLeft = c
                    break
                }
            }
        }

        const measures = {
            width: 1 + columnRight - columnLeft,
            height: 1 + lineBottom - lineTop,
            x: padding - columnLeft,
            y: padding - lineTop
        }

        try {
            drawContext.clearRect(0, 0, drawCanvas.width, drawCanvas.height)
            drawCanvas.width = measures.width
            drawCanvas.height = measures.height
            drawContext.drawImage(canvas, columnLeft, lineTop, measures.width, measures.height, 0, 0, measures.width, measures.height)
        } catch(e) {
            console.log('canvas error', e)
        }

        return measures
    }
}

export default TextCanvas
