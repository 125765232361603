<script>
export default {
    name: "ScreenshotItemClipart",
    props: {
        clipart: Object,
        variant: Object
    },
    computed: {
        styles() {
            const wPercent = this.clipart.width / this.variant.width
            const hPercent = this.clipart.height / this.variant.height
            const leftPercent = (this.clipart.x / this.variant.width) - (wPercent / 2)
            const topPercent = (this.clipart.y / this.variant.height) - (hPercent / 2)
            return {
                top: (topPercent * 100) + '%',
                left: (leftPercent * 100) + '%',
                width: (wPercent * 100) + '%',
                height: (hPercent  * 100) + '%',
                transform: 'rotate('+this.clipart.rotation+'deg)',
                color: this.variant.laserColor ? this.variant.laserColor : this.clipart.color
            }
        }
    }
}
</script>

<template>
    <div class="screenshot-item is-clipart" v-if="!clipart.deleted" :style="styles" v-html="clipart.svgCode">
    </div>
</template>
