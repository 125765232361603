<template>
    <section class="sidebar-section">
        <div class="sidebar-section-header inner box has-large-padding">
            <p class="sidebar-comment-help" v-if="commentHelp">
                {{ commentHelp }}
            </p>
            <h1 class="sidebar-title">
                <span>{{ $t('pao.title') }}</span>
                <button class="buttoninfo" @click="openCommentPopup">En savoir +</button>
            </h1>
            <textarea rows="3" class="form-textarea" :placeholder="$t('pao.messagePlaceholder')" v-model="message"></textarea>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'SidebarComments',
        computed: {
            commentHelp() {
                return this.$store.state.product.comment
            },
            message: {
                set(value) {
                    this.$store.commit('product/setHelp', value)
                },
                get() {
                    return this.$store.getters['product/help']
                }
            }
        },
        methods: {
            openCommentPopup() {
                this.$store.commit('popup/open', {
                    slug: 'PopupPao'
                })
            }
        }
    }
</script>
