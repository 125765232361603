<template>
    <svg :viewBox="'0 0 '+width+' '+height" class="svgicon" :width="width" :height="height" :class="classname">
        <use :xlink:href="'/images/icons.svg#' + icon"></use>
    </svg>
</template>

<script>
    export default {
        name: 'SvgSprite',
        props: {
            width: String,
            height: String,
            classname: String,
            icon: String
        }
    }
</script>