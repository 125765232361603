<script>

import ScreenshotItemImage from "./ScreenshotItemImage.vue";
import ScreenshotItemClipart from "./ScreenshotItemClipart.vue";
import ScreenshotItemText from "./ScreenshotItemText.vue";

export default {
    name: "Screenshot",
    components: {ScreenshotItemText, ScreenshotItemClipart, ScreenshotItemImage},
    props: {
        marquage: Object
    },
    computed: {
        currentProductImage() {
            const options = this.$store.getters['variants/selectedOptions']
            const subvariantSelected = this.$store.getters['variants/subvariantSelected']
            let currentImg = null
            for(const img of this.marquage.marquage.images) {
                for(const variant in options) {
                    if(img.variant == options[variant]) {
                        if(!subvariantSelected || subvariantSelected == img.subVariant) {
                            currentImg = img
                        }
                    }
                }
            }
            if(!currentImg) {
                currentImg = this.marquage.marquage.images ? this.marquage.marquage.images[0] : null
            }
            return currentImg ? currentImg : null
        },
        zoneStyle() {
            const v = this.marquage.variant
            const i = this.currentProductImage
            if(!v || !i) {
                return {}
            }
            return {
                top: ((v.startY / i.height) * 100) + '%',
                left: ((v.startX / i.width) * 100) + '%',
                width: ((v.width / i.width) * 100) + '%',
                height: ((v.height / i.width) * 100) + '%',
            }
        },
        getLabel() {
            return this.marquage.marquage.label
        }
    }
}
</script>

<template>
    <div class="screenshot" :data-label="getLabel">
        <img v-if="currentProductImage" :src="currentProductImage.url" alt="" class="screenshot-image">
        <div class="screenshot-zone" :style="zoneStyle">
            <ScreenshotItemImage v-for="image in marquage.marquage.config.images" :image="image" :variant="marquage.variant" />
            <ScreenshotItemClipart v-for="clipart in marquage.marquage.config.cliparts" :clipart="clipart" :variant="marquage.variant" />
            <ScreenshotItemText v-for="text in marquage.marquage.config.texts" :text="text" :variant="marquage.variant" />
        </div>
        <img src="/images/dimo-marine.png" alt="" class="screenshots-logo">
        <div class="screenshots-text">
            {{ $store.state.product.name }}<br>
            Ref. <b>{{ $store.state.product.refCata }}</b>
        </div>
    </div>
</template>
