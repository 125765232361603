<template>
    <div>
        <div class="popup-header">
            <div class="popup-title">Grille tarifaire</div>
            <div class="popup-subtitle">en € HT</div>
        </div>
        <div class="popup-content">
            <div class="popup-main">
                <GrilleTarif hide-header show-all />
            </div>
        </div>
    </div>
</template>

<script>
    import GrilleTarif from "../../pricebar/GrilleTarif.vue";

    export default {
        name: 'PopupGrilleTarifs',
        components: {GrilleTarif}
    }
</script>
