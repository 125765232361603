<template>
    <div class="mediagrid">
        <div class="mediagrid-inner">
            <perfect-scrollbar ref="scroll">
                <div class="mediagrid-items">
                    <MediaGridItem
                        v-for="item in items" :key="item.path" :item="item"
                        @toggle="toggleItem(item)" @imgLoad="onLoad"
                        :selected="itemSelected(item)"
                    />
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
    import MediaGridItem from './MediaGridItem'
    export default {
        name: 'MediaGrid',
        components: {MediaGridItem},
        props: {
            items: Array,
            selectionMax: Number
        },
        data: () => ({
            selected: []
        }),
        mounted() {
            this.$refs.scroll.update()
        },
        methods: {
            onLoad() {
                this.$refs.scroll.update()
            },
            toggleItem(item) {
                if(this.itemSelected(item)) {
                    this.selected = this.selected.filter(i => i.path !== item.path)
                } else {
                    this.selected.push(item)
                    if(this.selectionMax && this.selected.length > this.selectionMax) {
                        this.selected.shift()
                    }
                }
                this.$emit('change', this.selected)
            },
            itemSelected(item) {
                return this.selected.includes(item)
            }
        }
    }
</script>
