export default {
    /**
     * convert hexadecimal color to rgb values
     * @param h
     * @returns {[number, number, number]}
     */
    hexToRGB(h) {
        let r = 0, g = 0, b = 0

        // 3 digits
        if (h.length === 4) {
            r = '0x' + h[1] + h[1]
            g = '0x' + h[2] + h[2]
            b = '0x' + h[3] + h[3]

            // 6 digits
        } else if (h.length === 7) {
            r = '0x' + h[1] + h[2]
            g = '0x' + h[3] + h[4]
            b = '0x' + h[5] + h[6]
        }

        return [r, g, b]
    },

    /**
     * Converts an RGB color value to HSL. Conversion formula
     * Assumes r, g, and b are contained in the set [0, 255] and
     * returns h, s, and l in the set [0, 1].
     *
     * @param   Number  r       The red color value
     * @param   Number  g       The green color value
     * @param   Number  b       The blue color value
     * @return  Array           The HSL representation
     */
    rgbToHsl(r, g, b) {
        r /= 255, g /= 255, b /= 255

        var max = Math.max(r, g, b), min = Math.min(r, g, b)
        var h, s, l = (max + min) / 2

        if (max == min) {
            h = s = 0 // achromatic
        } else {
            var d = max - min
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0)
                    break
                case g:
                    h = (b - r) / d + 2
                    break
                case b:
                    h = (r - g) / d + 4
                    break
            }

            h /= 6
        }

        return [h, s, l]
    }
}
