<template>
    <div class="marking-rule" :class="classes" v-if="sizeFloored > 0">
        <div class="marking-rule-cm" v-for="n in sizeFloored"></div>
        <div class="marking-rule-space" v-if="sizeDigit" :style="{ flex: sizeDigit * 10 }"></div>
    </div>
</template>

<script>
    export default {
        name: 'ViewerMarkingRule',
        props: {
            size: Number,
            vertical: Boolean
        },
        computed: {
            classes() {
                return {
                    'is-horizontal': !this.vertical,
                    'is-vertical': this.vertical
                }
            },
            sizeFloored() {
                return Math.floor(this.size)
            },
            sizeDigit() {
                return this.size % 1
            }
        }
    }
</script>