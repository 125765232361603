<template>
    <transition name="options-slide">
        <div class="options" v-if="motif">
            <div class="options-icon">
                <svg-sprite icon="brush" width="20" height="20" />
            </div>
            <div class="options-group">
                <ColorPicker v-model="color" :disabled="disableColorEdition" :colors-preset="colorsPreset" />
            </div>
        </div>
    </transition>
</template>

<script>
    import {mapGetters} from 'vuex'
    import Constants from '../../config/Constants'
    import ColorPicker from '../picker/ColorPicker'
    import ViewerItemOptions from '../../mixins/ViewerItemOptions'

    export default {
        name: 'ViewerMotifOptions',
        components: {ColorPicker},
        mixins: [ViewerItemOptions],
        computed: {
            ...mapGetters({
                selectedMarquage: 'marquage/selected'
            }),
            motif() {
                return this.$store.getters['editor/selectedItem']({
                    marquage: this.selectedMarquage,
                    type: Constants.EDITOR.TYPE_CLIPART
                })
            },
            color: {
                get() {
                    return this.motif.item.color
                },
                set(value) {
                    this.$store.dispatch('editor/updateItemColor', {
                        index: this.motif.index,
                        color: value
                    })
                    this.$store.dispatch('history/snapshot')
                }
            },
            /**
             * if we used all colors available on technique, limit colors to already used ones
             */
            colorsPreset() {
                let preset = false
                const colors = this.$store.getters['editor/itemsColors'](this.selectedMarquage)

                if(this.technique.nbColorIncluded > 1 && colors.length >= this.technique.nbColorIncluded) {
                    preset = colors
                }

                return preset
            }
        }
    }
</script>
