<script>
export default {
    props: {
        variant: Object,
        technique: Object,
        marking: Object
    },
    computed: {
        itemPrice() {
            if(this.variant && this.variant.refmarq) {
                const price = this.$store.getters['cart/priceForTechnique'](this.variant.refmarq)
                if(price) {
                    return price.total
                } else {
                    return 0
                }
            } else {
                return 0
            }
        }
    }
}
</script>

<template>
    <div class="cartitem">
        <div class="cartitem-body">
            <div class="cartitem-thumb is-image">
                <img v-if="variant.urlIcon" :src="variant.urlIcon.replace('web-tab', 'localhost:8000')" alt="">
            </div>
            <div class="cartitem-name">
                <b>{{ variant.markingIncluded ? 'Marquage inclus ' : '' }}{{ technique.label }} {{ variant.label }}</b> ({{ marking.label }})
            </div>
            <div class="cartitem-price">
                {{ itemPrice | price }} HT
            </div>
            <button class="cartitem-delete" title="Supprimer" @click.prevent="$emit('delete')"></button>
        </div>
    </div>
</template>
