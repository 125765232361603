<template>
    <img :src="'/images/' + imgName + '.svg'" alt="">
</template>

<script>
    export default {
        name: 'Logo',
        props: {
            white: {
                type: Boolean
            }
        },
        computed: {
            imgName() {
                let name = 'logo'
                if(this.$store.getters['product/light']) {
                    name += '-light'
                }
                if(this.white) {
                    name += '-white'
                }
                return name
            }
        }
    }
</script>