<template>
    <div class="sidebar-light" :class="{'is-toggled': !contentShowed}">
        <div class="sidebar-light-head" @click="toggleContent">
            <div class="sidebar-light-title">Préparez votre personnalisation</div>
            <div class="sidebar-light-icon"></div>
        </div>
        <div class="sidebar-light-content" ref="content">
            <div class="sidebar-light-content-inner">
                <p>Choisissez votre quantité, votre marquage et votre logo dans les modules ci-dessous. Indiquez ensuite vos options de personnalisation dans la zone commentaires.</p>
                <p><b>Après validation de votre commande, vous recevrez un BAT sous 24/48h pour valider votre personnalisation.</b></p>
            </div>
        </div>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'

    export default {
        data: () => ({
            contentShowed: false
        }),
        mounted() {
            this.$refs.content.style.display = 'none'
            setTimeout(() => {
                this.toggleContent()
            }, 2000)
        },
        methods: {
            toggleContent() {
                this.contentShowed = !this.contentShowed
                Velocity(
                    this.$refs.content,
                    this.contentShowed ? 'slideDown' : 'slideUp',
                    {
                        duration: 500,
                        easing: 'easeOutCubic'
                    }
                )
            }
        }
    }
</script>
