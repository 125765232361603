<template>
    <div>
        <div class="popup-header">
            <div class="popup-title">{{ $t('motifs.title') }}</div>
            <div class="popup-subtitle">{{ $t('motifs.subtitle') }}</div>
        </div>
        <div class="popup-content">
            <div class="popup-side">
                <PopupMenu :links="menuLinks" v-model="currentMenu" :translate="false" v-if="motifs"/>
            </div>
            <div class="popup-main form" :class="{'is-loading': loading}">
                <div class="form-loader">
                    <loader/>
                </div>
                <div class="form-label has-arrow">{{ $t('motifs.help') }}</div>

                <template v-if="motifs">
                    <MediaGrid :items="category.cliparts" v-for="category in motifs"
                               :key="category.id" :selectionMax="3"
                               v-if="currentMenu === category.id"
                               @change="onSelectionChange(category, $event)"
                    />
                </template>
                <div v-else class="mediagrid"></div>

                <div class="form-confirm-button">
                    <button class="button is-large is-rounded" @click.prevent="validate">{{ $t('validateSelection') }}
                        ({{ globalSelection.length }})
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PopupMenu from '../PopupMenu'
    import MediaGrid from '../../ui/MediaGrid'
    import Vue from 'vue'
    import Constants from '../../../config/Constants'
    import {mapGetters} from 'vuex'

    export default {
        name: 'PopupMotifs',
        components: {MediaGrid, PopupMenu},
        data: () => ({
            currentMenu: null,
            selectedItems: {},
            loading: false,
        }),
        mounted() {
            if(!this.motifs) {
                this.loading = true
                this.$store.dispatch('clipart/getAll')
                    .then(() => {
                        this.loading = false
                        this.generateCats()
                    })
            } else {
                this.generateCats()
            }
        },
        computed: {
            ...mapGetters({
                motifs: 'clipart/items'
            }),
            /**
             * menu links : category list
             */
            menuLinks() {
                let links = {}
                if (this.motifs) {
                    for (const cat of Object.values(this.motifs)) {
                        links[cat.id] = cat.name
                    }
                }
                return links
            },
            /**
             * selection of all categories
             */
            globalSelection() {
                let selection = []
                for (const s of Object.values(this.selectedItems)) {
                    selection = [
                        ...selection,
                        ...s,
                    ]
                }
                return selection
            },
        },
        methods: {
            /**
             * validate selection, add motifs to the editor
             */
            validate() {
                const color = this.$store.getters['editor/latestItemColor'](this.$store.getters['marquage/selected'])
                for (const item of this.globalSelection) {
                    this.$store.commit('editor/addItem', {
                        marquage: this.$store.getters['marquage/selected'],
                        type: Constants.EDITOR.TYPE_CLIPART,
                        defaultValues: {
                            clipart: item,
                            color: color,
                        },
                    })
                }
                this.$store.commit('popup/close')
            },
            onSelectionChange(category, selection) {
                Vue.set(this.selectedItems, category.id, selection)
            },
            generateCats() {
                for (const cat of Object.values(this.motifs)) {
                    if(!this.currentMenu) {
                        this.currentMenu = cat.id
                    }
                    Vue.set(this.selectedItems, cat.code, [])
                }
            }
        },
    }
</script>
