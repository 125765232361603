import axios from 'axios'

const KEY_IMAGES = 'LIBRARY_IMAGES'
const KEY_SEARCHED_COLORS = 'LIBRARY_SEARCHED_COLORS'

let imagesLocal = localStorage.getItem(KEY_IMAGES)
if(imagesLocal) {
    try {
        imagesLocal = JSON.parse(imagesLocal)
        // remove images older than 60 days
        const currentTime = new Date().getTime()
        imagesLocal = imagesLocal.filter((img) => {
            return currentTime - img.created < 1000 * 60 * 60 * 24 * 60
        })
        localStorage.setItem(KEY_IMAGES, JSON.stringify(imagesLocal))
    } catch(e) {
        console.log(e)
    }
}

let colorsLocal = localStorage.getItem(KEY_SEARCHED_COLORS)
if(colorsLocal) {
    try {
        colorsLocal = JSON.parse(colorsLocal)
    } catch(e) {
        console.log(e)
    }
}

const state = {
    images: imagesLocal ? imagesLocal : [],
    searchedColors: colorsLocal ? colorsLocal : []
}

const getters = {
    images(state) {
        return state.images
    },
    searchedColors(state) {
        return state.searchedColors
    }
}

const actions = {
    /**
     * upload an image file to api
     * @param commit
     * @param file
     * @returns {Promise<AxiosResponse<T>>}
     */
    async uploadFile({commit}, {file}) {
        return new Promise((resolve, reject) => {
            const data = new FormData()
            data.append('file', file)

            axios.post(process.env.VUE_APP_API_URL + 'image/upload', data)
                .then((response) => {
                    if(response.data && response.data.obj && response.data.obj.url) {
                        commit('addImage', response.data.obj)
                        resolve({
                            imageObj: response.data.obj
                        })
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    /**
     * edit an image color and crop
     * @param commit
     * @param id
     * @param options
     * @param save
     * @param cropOptions
     * @returns {Promise<AxiosResponse<T>>}
     */
    async editImage({commit}, {id, options, nbColors = 1, save = false, cropOptions = null}) {
        return new Promise((resolve, reject) => {
            let optionsApi = {
                negate: options.negate ? 'true' : 'false',
                dominantColor: options.dominantColor,
                color2: nbColors > 1 ? options.color2 : null,
                threshold: options.threshold,
                nbColors: nbColors < 1 ? 1 : nbColors,
                save: save
            }
            if(cropOptions) {
                optionsApi = {
                    ...optionsApi,
                    ...cropOptions
                }
            }
            const url = process.env.VUE_APP_API_URL + 'image/marking-crop/' + id

            axios.post(url, optionsApi)
                .then((response) => {
                    if(response.data && (response.data.obj || response.data.imageUrl)) {
                        resolve(response.data)
                    } else {
                        reject(response)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

const mutations = {
    addImage(state, image) {
        const img = {
            ...image,
            created: new Date().getTime()
        }
        // remove images with same original file name
        state.images = state.images.filter(i => {
            return i.originalFilename !== img.originalFilename
        })

        // add image and save to localStorage
        state.images.unshift(img)
        localStorage.setItem(KEY_IMAGES, JSON.stringify(state.images))
    },
    removeImage(state, { id }) {
        state.images = state.images.filter((img) => {
            return img.id !== id
        })
        localStorage.setItem(KEY_IMAGES, JSON.stringify(state.images))
    },
    addSearchedColor(state, color) {
        // remove color if already exist in array
        state.searchedColors = state.searchedColors.filter(c => {
            return c.hex !== color.hex
        })
        state.searchedColors.unshift(color)
        // limit array length to 24
        state.searchedColors = state.searchedColors.splice(0, 24)
        localStorage.setItem(KEY_SEARCHED_COLORS, JSON.stringify(state.searchedColors))
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
