<template>
    <div class="refselect">
        <select name="refselect" id="refselect" @change="selectProduct" v-model="productRef">
            <option value="">Produit</option>
            <option :value="p.ref" v-for="p in products" :key="p.ref">{{ p.name }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        name: 'RefSelect',
        data: () => ({
            products: [
                {
                    ref: '994/Z1921',
                    name: 'Chargeur à induction Manoa 5 W'
                },
                {
                    ref: '373/P308.85',
                    name: 'Chargeur à induction 5 W pot à crayons Jolanda'
                },
                {
                    ref: '373/P610.86',
                    name: 'Stylo bille métal Alty'
                },
                {
                    ref: '255/4478',
                    name: 'Carnet de notes Aletta A5'
                },
                {
                    ref: '338/01159B',
                    name: 'Tee-shirt respirant femme, homme ou enfant 140 g blanc'
                },
                {
                    ref: '338/01159C',
                    name: 'Tee-shirt respirant femme, homme ou enfant 140 g couleur'
                },
            ],
            productRef: ''
        }),
        methods: {
            selectProduct() {
                if(this.productRef) {
                    window.location.href = window.location.origin + '?product=' + this.productRef
                }
            }
        }
    }
</script>

<style scoped>
    .refselect {
        position: absolute;
        top: 2px;
        left: 45px;
        z-index: 99;
    }
    .refselect select {
        width: 75px;
        background-color: #fff;
        border: 1px solid #EAEAEA;
        font-size: 12px;
    }
</style>
