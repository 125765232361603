<template>
    <div>
        <div class="popup-header">
            <div class="popup-title">{{ $t('imagecrop.title') }}</div>
            <div class="popup-subtitle">{{ $t('imagecrop.subtitle') }}</div>
        </div>
        <div class="popup-content">
            <div class="popup-main has-small-padding form" :class="{'is-loading': loading}">
                <div class="form-loader">
                    <loader />
                </div>
                <div class="cropper-container">
                    <cropper
                        v-if="imageUrl"
                        class="cropper"
                        :src="imageUrl"
                        :stencil-props="{}"
                        :debounce="50"
                        :canvas="false"
                        :minWidth="5"
                        :minHeight="5"
                        ref="cropper"
                        :defaultPosition="getDefaultPosition"
                        :defaultSize="getDefaultSize"
                    ></cropper>
                </div>
                <div class="form-confirm-button">
                    <button class="button is-large is-rounded" :class="{'is-loading': loading}" @click.prevent="validate">{{ $t('crop') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import { Cropper } from 'vue-advanced-cropper'
    import config from '../../../../config.json'
    import axios from 'axios'

    export default {
        name: 'PopupImageCrop',
        components: {
            Cropper
        },
        data: () => ({
            loading: false,
            imageUrl: '',
            image: null,
            imageOptions: null,
            imageCropOptions: null,
            originalImage: null
        }),
        mounted() {
            this.image = this.$store.state.popup.params.image
            this.imageOptions = this.$store.state.popup.params.image.options
            this.imageCropOptions = this.$store.state.popup.params.image.cropOptions
            this.originalImage = this.$store.state.popup.params.image.originalObj
            this.generateOriginalImage()
        },
        computed: {
            ...mapGetters({
                selectedMarquage: 'marquage/selected',
            })
        },
        methods: {
            /**
             * generate image from original, with color options
             */
            generateOriginalImage() {
                this.loading = true
                this.$store.dispatch('library/editImage', {
                    options: this.imageOptions,
                    save: false,
                    id: this.originalImage.id,
                })
                    .then((response) => {
                        this.imageUrl = response.imageUrl
                        this.loading = false
                    })
                    .catch((error) => {
                        console.log(error)
                        this.loading = false
                    })
            },
            getDefaultPosition() {
                const crop = this.$store.state.popup.params.image.cropOptions
                return {
                    left: crop ? crop.x : 0,
                    top: crop ? crop.y : 0
                }
            },
            getDefaultSize(params) {
                const crop = this.$store.state.popup.params.image.cropOptions
                return {
                    width: crop ? crop.width : params.imageSize.width,
                    height: crop ? crop.height : params.imageSize.height
                }
            },
            async validate() {
                // get crop rectangle coordinates
                const crop = this.$refs.cropper.getResult()
                const coords = {
                    width: Math.round(crop.coordinates.width),
                    height: Math.round(crop.coordinates.height),
                    x: Math.round(crop.coordinates.left),
                    y: Math.round(crop.coordinates.top)
                }

                // calculate new image size on editor
                const newSize = {
                    width: Math.round((coords.width / this.image.imageObj.width) * this.image.width),
                    height: Math.round((coords.height / this.image.imageObj.height) * this.image.height)
                }

                this.loading = true
                try {
                    const response = await this.$store.dispatch('library/editImage', {
                        options: this.imageOptions,
                        save: true,
                        id: this.originalImage.id,
                        cropOptions: coords
                    })

                    this.$store.commit('editor/updateItemProperties', {
                        index: this.$store.state.popup.params.index,
                        properties: {
                            ...newSize,
                            imageObj: response.obj,
                            cropOptions: coords
                        }
                    })
                    this.$store.dispatch('history/snapshot')
                    this.$store.commit('popup/close')
                } catch(e) {
                    this.$store.commit('notification/open', {
                        message: this.$t('apiError'),
                        error: true
                    })
                }
                this.loading = false
            }
        }
    }
</script>
