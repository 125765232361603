<template>
    <div>
        <div class="popup-header">
            <div class="popup-title">{{ $t('sharePopup.title') }}</div>
            <div class="popup-subtitle">{{ $t('sharePopup.subtitle') }}</div>
        </div>
        <div class="popup-content">
            <div class="popup-main">
                <div class="form" :class="{'is-loading': loading}">
                    <div class="form-loader">
                        <loader />
                    </div>
                    <div class="form-label">{{ $t('sharePopup.shareLink') }}</div>
                    <div class="form-line">
                        <div class="form-control">
                            <textarea rows="2" class="form-textarea" :value="shareUrl" readonly ref="shareInput"></textarea>
                        </div>
                    </div>
                    <button class="button is-large is-rounded is-hidden-mobile" @click.prevent="copyUrl" :class="{ 'is-success':
                    copied }">
                        {{ copied ? $t('sharePopup.copied') : $t('sharePopup.copy') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PopupMenu from '../PopupMenu'
    import axios from 'axios'
    import config from '../../../../config.json'

    export default {
        name: 'PopupShare',
        components: {PopupMenu},
        data: () => ({
            loading: false,
            currentMenu: 'Share',
            shareUrl: '',
            copied: false
        }),
        mounted() {
            this.saveCustom()
        },
        computed: {
            menuLinks() {
                return {
                    Share: 'sharePopup.link'
                }
            }
        },
        methods: {
            /**
             * save current custom to obtain share link
             * @returns {Promise<void>}
             */
            async saveCustom() {
                this.loading = true
                const custom = await this.$store.dispatch('getCustomization')
                axios.post(process.env.VUE_APP_API_URL + 'save', custom, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                     .then((response) => {
                         this.loading = false
                         if(response.data && response.data.success) {
                             this.shareUrl = window.location.origin + '?share=' + response.data.id
                         }
                     })
                     .catch((error) => {
                         this.loading = false
                     })
            },
            copyUrl() {
                this.$refs.shareInput.select()
                document.execCommand('copy')
                this.$refs.shareInput.selectionEnd = this.$refs.shareInput.selectionStart
                this.$refs.shareInput.blur()
                this.copied = true
            },
            /**
             * open social share popup
             */
            socialShare(network) {
                if(this.shareUrl) {
                    let href = ''
                    const shareUrlEncoded = encodeURI(this.shareUrl)
                    const title = 'Dimo Custom'
                    switch(network) {
                        case 'facebook':
                            href = 'https://www.facebook.com/sharer/sharer.php?u=' + shareUrlEncoded
                            break;
                        case 'twitter':
                            href = 'https://twitter.com/intent/tweet?text=' + shareUrlEncoded
                            break;
                        case 'pinterest':
                            href = 'http://pinterest.com/pin/create/button/?url=' + shareUrlEncoded + '&description=' + title
                            break;
                        case 'linkedin':
                            href = 'https://www.linkedin.com/shareArticle?mini=true&url=' + shareUrlEncoded + '&title=' + title
                            break;
                    }
                    window.open(href, 'socialShareWindow', 'height=500, width=650, top=' + Math.round(window.innerHeight / 2 - 325) + ', left=' + Math.round(window.innerWidth / 2 - 250) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                }
            }
        }
    }
</script>
